type QueryMetricsType = {
  dateRange: string[] | undefined;
  year: string | undefined;
  month: string | undefined;
  operator: string | undefined;
  region: string | undefined;
  satellite: string | undefined;
  typePacket: string | undefined;
  devAddress: string | undefined;
  deliveryStatus: string | undefined;
  packetLength: string | undefined;
  resultCode: string | undefined;
};

export const makeMetricsQuery = ({
  dateRange,
  operator,
  region,
  satellite,
  typePacket,
  devAddress,
  deliveryStatus,
  packetLength,
  resultCode,
}: Partial<QueryMetricsType>) => {
  const queryParts: { [key: string]: string } = {};

  if (dateRange) {
    queryParts.from = dateRange[0];
    queryParts.to = dateRange[1];
  }

  if (operator) queryParts.operator = operator;
  if (region) queryParts.region = region;
  if (satellite) queryParts.satellite = satellite;
  if (typePacket) queryParts.type = typePacket;
  if (devAddress) queryParts.dev_addr = devAddress;
  if (deliveryStatus) queryParts.delivery_status = deliveryStatus;
  if (packetLength) queryParts.packet_length = packetLength;
  if (resultCode) {
    queryParts.result_code = resultCode;
    if (resultCode === 'No Response') {
      queryParts.result_code = '';
    }
  }

  const query = Object.keys(queryParts)
    .map((key) => `${key}=${queryParts[key]}`)
    .join('&');
  return query ? `?${query}` : '';
};

export const generateYears = (startYear: number): number[] => {
  const currentYear = new Date().getFullYear(),
    years = [];
  startYear = startYear || 2019;
  while (startYear <= currentYear) {
    years.push(startYear++);
  }
  return years;
};

export const adaptQueryStringForYear = (
  queries: string,
  year: string | undefined,
) => {
  if (!year) return queries;
  const splittedQuery = queries.split('&');

  splittedQuery[0] = `?from=${year}-01-01`;
  splittedQuery[1] = `to=${year}-12-01`;

  const newQuery = splittedQuery.join('&');
  return newQuery;
};

export const addNonexistentAttribut = (
  list: { date: string; messages_count: number; packetLength: number }[],
  shape: string[],
): { date: string; messages_count: number; packetLength: number }[] => {
  shape.map((value) => {
    if (!list.find((el) => value === el.date)) {
      list.push({
        date: value,
        messages_count: 0,
        packetLength: 0,
      });
    }
  });
  return list;
};

export function Capitalize(str?: string) {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
}

export function validateEmail(email?: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
}
