import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { Outlet } from 'react-router-dom';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { makeStyles } from '@mui/styles';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';

import BatchAutocomplete from '../Components/BatchAutocomplete';

const useStyles = makeStyles(() => ({
  btnMenu: {
    '&:hover': {
      background: '#a4b0be33',
    },
    backgroundColor: '#8e99b67a',
    borderRadius: '11px',
    marginLeft: '5em',
    border: 'none',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  },
  iconMenu: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100% !important',
  },
}));

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const StyledLink = styled(Link)(() => ({
  color: 'inherit',
}));

export default function ClippedDrawer() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // TODO: to delete
  });

  async function getBatches() {
    // await getAllMetrics(dispatchMetrics);
  }

  const handleDrawerOpen = () => {
    getBatches();
    setOpen(!open);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Box component="div" style={{ display: 'flex' }}>
            <SatelliteAltIcon style={{ marginRight: '20' }} />
            <Typography variant="h6" noWrap component="div">
              ELO tracking
            </Typography>
          </Box>

          <button onClick={handleDrawerOpen} className={classes.btnMenu}>
            <MenuIcon className={classes.iconMenu} />
          </button>
          <Box component="div" style={{ marginLeft: 'auto' }}>
            <BatchAutocomplete />
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="persistent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        anchor="left"
        open={open}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <StyledLink to="/">
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </ListItem>
            </StyledLink>
            <StyledLink to="/stats">
              <ListItem button>
                <ListItemIcon>
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText>Stats</ListItemText>
              </ListItem>
            </StyledLink>
          </List>
          <Divider />
          <List>
            {['Guide', 'Contact us'].map((text, index) => (
              <ListItem button key={text}>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Box sx={{ flexGrow: 1 }}>
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
}
