import * as React from 'react';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import EarthMap from './../Components/EarthMap';
import Stepper from './../Components/Stepper';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ClippedDrawer() {
  return (
    <React.Fragment>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <EarthMap />
        </Grid>
        <Grid item xs={6}>
          <Item>
            <Stepper />
          </Item>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
