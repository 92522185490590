import * as React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import OperatorsInput from '../OperatorsInput';
import SatelliteInput from '../SatelliteInput';
import DevAddressInput from '../DevAddressInput';
import DateRangeInput from '../DateRangeInput';
import DeliveryStatusInput from '../DeliveryStatusInput';
import PacketLengthInput from '../PacketLengthInput';
import PermissionsGate from '../../../Utils/permission/PermissionsGate';
import { ROLES } from '../../../Utils/permission/permission-maps';

const useStyles = makeStyles((theme: Theme) => ({
  inputs: {
    margin: '25px 0',
  },
  headers: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
  },
}));

export default function LogsFilters({
  onSubmit,
  disabled,
}: {
  onSubmit: () => void;
  disabled: boolean;
}) {
  const classes = useStyles();

  return (
    <>
      <Box>
        <div className={classes.headers}>Filters:</div>
        <Box component="div" className={classes.inputs}>
          <DateRangeInput />
        </Box>
        <PermissionsGate roles={[ROLES.admin]}>
          <Box component="div" className={classes.inputs}>
            <OperatorsInput />
          </Box>
        </PermissionsGate>
        <Box component="div" className={classes.inputs}>
          <SatelliteInput />
        </Box>
        <Box component="div" className={classes.inputs}>
          <DevAddressInput />
        </Box>
        <Box component="div" className={classes.inputs}>
          <DeliveryStatusInput />
        </Box>
        <Box component="div" className={classes.inputs}>
          <PacketLengthInput />
        </Box>
        <Box component="div" className={classes.inputs}>
          <Button
            fullWidth
            variant="contained"
            disabled={disabled}
            onClick={onSubmit}
          >
            Run Filter
          </Button>
        </Box>
      </Box>
    </>
  );
}
