import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import { getMessagesPerSat } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import DoughnutWrapper from './DoughnutWrapper';

export default function DoughnutProcessedSat({
  dataType,
}: {
  dataType: string;
}) {
  const { stateMetrics } = useMetrics();
  const dataChart = getMessagesPerSat(stateMetrics.metricsErrors, dataType);
  const labels =
    Object.keys(dataChart).length > 0
      ? Object.keys(dataChart)
      : ['Rejected messages'];
  const data =
    Object.values(dataChart).length > 0 ? Object.values(dataChart) : [0];

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };

  return (
    <React.Fragment>
      <DoughnutWrapper
        title={'Rejected ' + dataType + '/Sat'}
        data={chartData}
      />
    </React.Fragment>
  );
}
