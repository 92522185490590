import _ from 'lodash';
import { SerializedCountMetric } from '../Types';

export type SatsData = {
  [key: string]: {
    last_batch: {
      time_received: string | undefined;
      nb_packets: number;
      nb_bytes: number;
      status: string;
    };
    total: { nb_batches: number; nb_packets: number; nb_bytes: number };
  };
};
export default function satellitesData(
  metricsInput: SerializedCountMetric[],
  metricsOutput: SerializedCountMetric[],
) {
  const sats = metricsInput.reduce(
    (
      previousValue: { [key: string]: SerializedCountMetric[] },
      currentValue: SerializedCountMetric,
    ) => {
      if (!previousValue[currentValue.satellite])
        previousValue[currentValue.satellite] = [];
      previousValue[currentValue.satellite].push(currentValue);
      return previousValue;
    },
    {},
  );

  const satsData: SatsData = {};
  Object.keys(sats).map((sat) => {
    if (!satsData[sat])
      satsData[sat] = {
        last_batch: {
          time_received: '',
          nb_packets: 0,
          nb_bytes: 0,
          status: '',
        },
        total: { nb_batches: 0, nb_packets: 0, nb_bytes: 0 },
      };
    const lastBatch = sats[sat].sort(
      (a, b) =>
        new Date(b?.ingested_at || '').getTime() -
        new Date(a?.ingested_at || '').getTime(),
    )[0];

    const lastBatches = sats[sat].filter(
      (batch) => batch.document_id === lastBatch.document_id,
    );

    const totalMsgsofLastBatch = _.sumBy(lastBatches, function (o) {
      return o.messages_count;
    });
    const totalPacketsofLastBatch = _.sumBy(lastBatches, function (o) {
      return Number(o.packetLength);
    });

    satsData[sat]['last_batch'] = {
      time_received: lastBatch.ingested_at,
      nb_packets: totalMsgsofLastBatch,
      nb_bytes: totalPacketsofLastBatch,
      status: isBatchProcessed(lastBatch.document_id, metricsOutput),
    };
    const totalMsgs = _.sumBy(sats[sat], function (o) {
      return o.messages_count;
    });
    const totalPackets = _.sumBy(sats[sat], function (o) {
      return Number(o.packetLength);
    });

    const nbBatches = _.uniqBy(sats[sat], '_id').length;

    satsData[sat]['total'] = {
      nb_batches: nbBatches,
      nb_packets: totalMsgs,
      nb_bytes: totalPackets,
    };
  });

  return satsData;
}

const isBatchProcessed = (
  batch_id: string | undefined,
  metricsOutput: SerializedCountMetric[],
) => {
  const isExist = metricsOutput.find((value) => value.document_id === batch_id);
  return isExist ? 'Processed' : 'Error';
};
