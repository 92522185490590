import * as React from 'react';
import loraOperatorsConfig from '../Api/lora-operators-config';
import { loraConfigType } from './../Types/index';

type Action =
  | {
      type: 'get_lora_operators_configs';
      lora_operators_configs: [];
    }
  | { type: 'loading' }
  | { type: 'finish_loading' };

type Dispatch = (action: Action) => void;

type State = {
  lora_operators_configs: loraConfigType[];
  loaded: boolean;
};

type ProviderProps = { children: React.ReactNode };

const StateContext = React.createContext<
  { stateLoraConfigsList: State; dispatchLoraConfigsList: Dispatch } | undefined
>(undefined);

function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'get_lora_operators_configs': {
      return {
        lora_operators_configs: action.lora_operators_configs,
        loaded: true,
      };
    }
    case 'loading': {
      return {
        lora_operators_configs: state.lora_operators_configs,
        loaded: false,
      };
    }
    case 'finish_loading': {
      return {
        lora_operators_configs: state.lora_operators_configs,
        loaded: false,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function LoraConfigsListProvider({ children }: ProviderProps) {
  const [stateLoraConfigsList, dispatchLoraConfigsList] = React.useReducer(
    reducer,
    {
      lora_operators_configs: [],
      loaded: true,
    },
  );
  const value = { stateLoraConfigsList, dispatchLoraConfigsList };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
}

function useLoraConfigsList() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error('useMetrics must be used within a Provider');
  }
  return context;
}

async function getAllloraOperatorsConfigs(dispatch: Dispatch) {
  try {
    dispatch({ type: 'loading' });

    const [{ data: results }] = await Promise.all([
      loraOperatorsConfig.listLoraOperatorsConfig(),
    ]);
    const lora_operators_configs: [] = results;

    dispatch({
      type: 'get_lora_operators_configs',
      lora_operators_configs,
    });
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
  }
}

async function postLoraOperatorConfig(
  dispatch: Dispatch,
  loraConfigData: FormData,
) {
  try {
    dispatch({ type: 'loading' });

    await loraOperatorsConfig.createLoraOperatorConfig(loraConfigData);

    const [{ data: results }] = await Promise.all([
      loraOperatorsConfig.listLoraOperatorsConfig(),
    ]);
    const lora_operators_configs: [] = results;

    dispatch({
      type: 'get_lora_operators_configs',
      lora_operators_configs,
    });
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
  }
}

async function deleteLoraOperatorConfig(
  dispatch: Dispatch,
  configName: string,
) {
  try {
    dispatch({ type: 'loading' });

    await loraOperatorsConfig.deleteLoraOperatorConfig(configName);

    const [{ data: results }] = await Promise.all([
      loraOperatorsConfig.listLoraOperatorsConfig(),
    ]);
    const lora_operators_configs: [] = results;

    dispatch({
      type: 'get_lora_operators_configs',
      lora_operators_configs,
    });
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
  }
}

async function updateLoraOperatorConfig(
  dispatch: Dispatch,
  configId: string | undefined,
  loraConfigData: FormData,
) {
  try {
    dispatch({ type: 'loading' });

    await loraOperatorsConfig.updateLoraOperatorConfig(
      configId,
      loraConfigData,
    );

    const [{ data: results }] = await Promise.all([
      loraOperatorsConfig.listLoraOperatorsConfig(),
    ]);
    const lora_operators_configs: [] = results;

    dispatch({
      type: 'get_lora_operators_configs',
      lora_operators_configs,
    });
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
  }
}

async function testConnectionLoraOperatorConfig(
  dispatch: Dispatch,
  loraConfigData: FormData,
) {
  try {
    dispatch({ type: 'loading' });
    const result = await loraOperatorsConfig.testConnectionLoraOperatorConfig(
      loraConfigData,
    );
    dispatch({ type: 'finish_loading' });
    return result;
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
    throw error;
  }
}

export {
  LoraConfigsListProvider,
  useLoraConfigsList,
  getAllloraOperatorsConfigs,
  postLoraOperatorConfig,
  updateLoraOperatorConfig,
  deleteLoraOperatorConfig,
  testConnectionLoraOperatorConfig,
};
