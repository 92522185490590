import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import defaultInputs from '../../Constants/defaultInputs';
import { useFilters } from '../../Contexts/filters-context';
import { useFiltersList } from '../../Contexts/filtersList-context';

export default function DevAddressInput() {
  const { dispatchFilters } = useFilters();
  const { stateFiltersList } = useFiltersList();
  const firstValue = { label: 'Any devAddress', value: '' };
  const [devAddresses, setDevAddresses] = React.useState<
    { label: string; value: string }[]
  >([firstValue]);

  function prepareListDevAddress(devAddressesFromServer: []) {
    setDevAddresses([]);
    const preparedList = [firstValue];
    devAddressesFromServer.map((element) => {
      preparedList.push({
        label: element,
        value: element,
      });
    });
    setDevAddresses(preparedList);
  }

  React.useEffect(() => {
    prepareListDevAddress(stateFiltersList.devAddrsList);
  }, [stateFiltersList.devAddrsList]);

  const defaultValue = devAddresses.find(
    (option) => option.value === defaultInputs.devAddress,
  );

  return (
    <Autocomplete
      onChange={async (event, option) => {
        dispatchFilters({
          type: 'set_filters',
          filters: { devAddress: option.value },
        });
      }}
      isOptionEqualToValue={(selectedOption, option) =>
        selectedOption.value === option.value
      }
      disablePortal
      id="combo-box-devAddress"
      size="small"
      options={devAddresses}
      defaultValue={defaultValue}
      disableClearable={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label="DevAddress"
          placeholder="Select DevAddress"
        />
      )}
    />
  );
}
