import apiClient from '../Utils/apiClient';
import userApi from './user';

const getInputMetrics = async (queryString?: string) => {
  userApi.addTokenToHeader();
  return apiClient.get(`metrics/input${queryString}`);
};

const getOutputMetrics = async (queryString?: string) => {
  userApi.addTokenToHeader();
  return apiClient.get(`metrics/output${queryString}`);
};

const getErrorsMetrics = async (queryString?: string) => {
  userApi.addTokenToHeader();
  return apiClient.get(`metrics/errors${queryString}`);
};

const getDashboardData = async (queryString?: string) => {
  userApi.addTokenToHeader();
  return apiClient.get(`metrics/dashboard-table-data${queryString}`);
};

export default {
  getInputMetrics,
  getOutputMetrics,
  getErrorsMetrics,
  getDashboardData,
};
