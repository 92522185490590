import * as React from 'react';
import { DataGrid, GridColDef, GridCellParams } from '@mui/x-data-grid';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

import { Log } from './../../../Types';

const columns: GridColDef[] = [
  {
    field: 'dev_addr',
    headerName: 'Dev Addr',
    width: 120,
    sortable: false,
  },
  {
    field: 'operator',
    headerName: 'Operator',
    width: 200,
    sortable: false,
  },
  {
    field: 'result_code',
    headerName: 'Status',
    width: 140,
    renderCell: (params) => <StatusCell params={params} />,
    sortable: false,
  },
  {
    field: 'packet_length',
    headerName: 'Packet length',
    type: 'number',
    width: 120,
    sortable: false,
  },
  {
    field: 'recv_time',
    type: 'dateTime',
    headerName: 'Receive Time',
    width: 180,
    sortable: true,
  },
  {
    field: 'sent_time',
    type: 'dateTime',
    headerName: 'Sent Time',
    width: 180,
    sortable: true,
  },
  {
    field: 'response_from_operator_time',
    type: 'dateTime',
    headerName: 'response_time',
    width: 180,
    sortable: true,
  },
  {
    field: 'fCnt',
    headerName: 'fCnt',
    width: 120,
    sortable: true,
  },
  {
    field: 'snr',
    headerName: 'SNR',
    width: 180,
    sortable: false,
  },
  {
    field: 'rssi',
    headerName: 'RSSI',
    width: 150,
    sortable: false,
  },
  {
    field: 'frequency_mhz',
    headerName: 'Frequency (mhz)',
    type: 'number',
    width: 150,
    sortable: false,
  },
  {
    field: 'frequency_shift',
    headerName: 'Frequency shift',
    type: 'number',
    width: 150,
    sortable: false,
  },
  {
    field: 'sat_gps',
    headerName: 'Sat GPS',
    width: 120,
    sortable: false,
  },
  {
    field: 'satellite',
    headerName: 'Satellite',
    width: 150,
    sortable: false,
  },
  {
    field: 'payload_first_bytes',
    headerName: 'First bytes',
    width: 100,
    sortable: false,
  },
];

const StatusCell = ({ params }: { params: GridCellParams }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <Button
        variant="text"
        onClick={handlePopoverOpen}
        sx={{
          textTransform: 'capitalize',
        }}
      >
        {params.value || 'No Response'}
        <InfoIcon sx={{ fontSize: 12, ml: 1 }} />
      </Button>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        PaperProps={{
          style: { width: '70%', padding: '20px', wordWrap: 'break-word' },
        }}
      >
        <Typography variant="h6">Request :</Typography>
        <Typography variant="body2">{params.row.request}</Typography>
        <Divider sx={{ my: 2 }} />
        <Typography variant="h6">Response :</Typography>
        <Typography variant="body2">
          {params.value
            ? params.row.response
            : 'No Response From Network Server : ' + params.row.response}
        </Typography>
      </Popover>
    </div>
  );
};

export default function GridLogs({
  logs,
  loading,
}: {
  logs: Log[];
  loading: boolean;
}) {
  return (
    <div
      style={{ width: '100%', height: '100%', minHeight: 270, maxHeight: 800 }}
    >
      <DataGrid
        rows={logs}
        columns={columns}
        pageSize={25}
        rowsPerPageOptions={[25]}
        loading={loading}
      />
    </div>
  );
}
