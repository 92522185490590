import * as React from 'react';
import TextField from '@mui/material/TextField';

import defaultInputs from '../../Constants/defaultInputs';
import { useFilters } from '../../Contexts/filters-context';

export default function DeliveryStatusInput() {
  const { dispatchFilters } = useFilters();


  return (
    <TextField
      onChange={event => {
        dispatchFilters({
          type: 'set_filters',
          filters: { packetLength: event.target.value },
        });
      }}
      id="packet-length-input"
      size="small"
      fullWidth
      defaultValue={defaultInputs.packet_length}
      label="Packet longer than"
      InputLabelProps={{ shrink: true }}
    />
  );
}
