import React from 'react';
import { Line } from 'react-chartjs-2';
import Box from '@mui/material/Box';

import { useMetrics } from '../../Contexts/metrics-context';
import { lineFormat } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import defaultGraphOptions from './../../Constants/defaultGraphOptions';
import ChartWrapper from './ChartWrapper';
import { Capitalize } from '../../Utils/util';

export default function ChartMessagePerDay({ dataType }: { dataType: string }) {
  const { stateMetrics } = useMetrics();
  
  const formattedDataInput = React.useMemo(
    () => lineFormat(stateMetrics.metricsInput, dataType),
    [stateMetrics.metricsInput, dataType],
  );
  const formattedDataOutput = React.useMemo(
    () => lineFormat(stateMetrics.metricsOutput, dataType),
    [stateMetrics.metricsOutput, dataType],
  );

  const data = {
    datasets: [
      {
        label: 'Input ' + dataType,
        data: formattedDataInput,
        borderColor: chartColors.lineCharts.borders[0],
        backgroundColor: chartColors.lineCharts.backgrounds[0],
      },
      {
        label: 'Output ' + dataType,
        data: formattedDataOutput,
        borderColor: chartColors.lineCharts.borders[1],
        backgroundColor: chartColors.lineCharts.backgrounds[1],
      },
    ],
  };
  return (
    <React.Fragment>
      <ChartWrapper title={Capitalize(dataType) + ' per day'}>
        <Box style={{ height: '400px', margin: 'auto' }}>
          <Line options={defaultGraphOptions.line} data={data} />
        </Box>
      </ChartWrapper>
    </React.Fragment>
  );
}
