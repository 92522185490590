import * as React from 'react';
import GridOperators from '../../Components/Partner/Configuration/GridOperators';
import {
  useLoraConfigsList,
  getAllloraOperatorsConfigs,
} from '../../Contexts/lora-operators-config-context';

export default function Configuration() {
  const { dispatchLoraConfigsList } = useLoraConfigsList();

  React.useEffect(() => {
    async function getDefaultData() {
      await Promise.all([getAllloraOperatorsConfigs(dispatchLoraConfigsList)]);
    }
    getDefaultData();
  }, []);

  return (
    <div>
      <GridOperators />
    </div>
  );
}
