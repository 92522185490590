import React from 'react';
import { ThemeProvider } from '@mui/material/styles';

import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import theme from './Theme';
import GlobalStyles from './Components/GlobalStyles';
import PartnerRoutes from './Navigation/PartnerRoutes';
import TrackingRoutes from './Navigation/TrackingRoutes';
import { MetricsProvider } from './Contexts/metrics-context';
import { LogsProvider } from './Contexts/logs-context';
import { FiltersProvider } from './Contexts/filters-context';
import { FiltersListProvider } from './Contexts/filtersList-context';
import { LoraConfigsListProvider } from './Contexts/lora-operators-config-context';
import { isTrackingDomain } from './Utils/domain';
import CssBaseline from '@mui/material/CssBaseline';
import { setChartOptionsByType } from './Utils/chartjs/optionsHandler';
import defaultGraphOptions from './Constants/defaultGraphOptions';
import { Chart } from 'chart.js';
import { LicenseInfo } from '@mui/x-license-pro';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

/*TODO load all default chart options (in ./Utils/chartjs/optionsHandler)*/
setChartOptionsByType(defaultGraphOptions.doughnut, Chart.overrides.doughnut);

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MetricsProvider>
          <FiltersProvider>
            <FiltersListProvider>
              <LogsProvider>
                <LoraConfigsListProvider>
                  <GlobalStyles />
                  {isTrackingDomain() ? <TrackingRoutes /> : <PartnerRoutes />}
                </LoraConfigsListProvider>
              </LogsProvider>
            </FiltersListProvider>
          </FiltersProvider>
        </MetricsProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
