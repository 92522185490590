import * as React from 'react';
import filtersListApi from '../Api/filters-list';
import { FilterObject } from '../Types';

type Action =
  // TODO delete and check side effect
  | {
      type: 'get_filtersList';
      operatorsList: [];
      devAddrsList: [];
      typesList: [];
      satellitesList: string[];
      regionsList: [];
      statusList: string[];
    }
  | {
      type: 'set_log_filtersList';
      operatorsList: [];
      devAddrsList: [];
      typesList: [];
      satellitesList: string[];
      regionsList: [];
      statusList: string[];
    }
  | {
      type: 'set_dashboard_filtersList';
      operatorsList: [];
      devAddrsList: [];
      typesList: [];
      satellitesList: string[];
      regionsList: [];
    }
  | { type: 'get_filtersList_loading' }
  | { type: 'finish_loading' };

type Dispatch = (action: Action) => void;
type State = {
  operatorsList: [];
  devAddrsList: [];
  typesList: [];
  satellitesList: string[];
  regionsList: [];
  statusList?: string[];
  filterList_loaded: boolean;
};

type ProviderProps = { children: React.ReactNode };

const StateContext = React.createContext<
  { stateFiltersList: State; dispatchFiltersList: Dispatch } | undefined
>(undefined);

function reducer(state: State, action: Action) {
  switch (action.type) {
    // TODO delete and check side effect
    case 'get_filtersList': {
      return {
        ...action,
        filterList_loaded: true,
      };
    }
    case 'set_log_filtersList': {
      return {
        ...action,
        filterList_loaded: true,
      };
    }
    case 'set_dashboard_filtersList': {
      return {
        ...action,
        filterList_loaded: true,
      };
    }
    case 'get_filtersList_loading': {
      return {
        ...state,
        filterList_loaded: false,
      };
    }
    case 'finish_loading': {
      return {
        ...state,
        filterList_loaded: true,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${JSON.stringify(action)}`);
    }
  }
}

function FiltersListProvider({ children }: ProviderProps) {
  const [stateFiltersList, dispatchFiltersList] = React.useReducer(reducer, {
    operatorsList: [],
    devAddrsList: [],
    typesList: [],
    satellitesList: [],
    regionsList: [],
    statusList: [],
    filterList_loaded: true,
  });
  const value = { stateFiltersList, dispatchFiltersList };
  return (
    <StateContext.Provider value={value}>{children}</StateContext.Provider>
  );
}

function useFiltersList() {
  const context = React.useContext(StateContext);
  if (context === undefined) {
    throw new Error('useMetrics must be used within a Provider');
  }
  return context;
}

// TODO delete and check side effect
async function getAllFiltersList(dispatch: Dispatch) {
  try {
    dispatch({ type: 'get_filtersList_loading' });

    const { data: results } = await filtersListApi.filtersList();

    const operatorsList = results['operatorsList'];
    const regionsList: [] = results['regionsList'];
    const devAddrsList: [] = [];
    const satellitesList = ['ELO-0'];
    const typesList: [] = [];
    const statusList: string[] = [];

    dispatch({
      type: 'get_filtersList',
      devAddrsList,
      operatorsList,
      regionsList,
      satellitesList,
      typesList,
      statusList,
    });
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
  }
}

async function updateFiltersList(
  dispatch: Dispatch,
  filterObject: FilterObject,
) {
  try {
    dispatch({ type: 'get_filtersList_loading' });

    const devAddrsList: [] = filterObject?.devAddrsList;
    const operatorsList = filterObject?.operatorsList;
    const regionsList: [] = filterObject?.regionsList;
    const satellitesList = filterObject?.satellitesList;
    const typesList: [] = filterObject?.typesList;
    const statusList: string[] = filterObject?.statusList;

    dispatch({
      type: 'set_log_filtersList',
      devAddrsList,
      operatorsList,
      regionsList,
      satellitesList,
      typesList,
      statusList,
    });
  } catch (error) {
    console.error({ error });
    dispatch({ type: 'finish_loading' });
  }
}

async function resetFliterList(dispatchFiltersList: Dispatch) {
  dispatchFiltersList({
    type: 'get_filtersList',
    devAddrsList: [],
    operatorsList: [],
    regionsList: [],
    satellitesList: [],
    typesList: [],
    statusList: [],
  });
}

export {
  FiltersListProvider,
  useFiltersList,
  getAllFiltersList,
  resetFliterList,
  updateFiltersList,
};

export type filtersListDispatch = Dispatch;
