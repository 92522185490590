import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import defaultInputs from '../../Constants/defaultInputs';
import { useFilters } from '../../Contexts/filters-context';
import { useFiltersList } from '../../Contexts/filtersList-context';

export default function OperatorsInput() {
  const { dispatchFilters } = useFilters();
  const { stateFiltersList } = useFiltersList();
  const firstValue = { label: 'Any operator', operator: '' };
  const [operators, setOperators] = React.useState<
    { label: string; operator: string }[]
  >([firstValue]);

  function prepareListOperators(operatorsFromServer: []) {
    const preparedList = [firstValue];
    operatorsFromServer.map((element) => {
      preparedList.push({
        label: element,
        operator: element,
      });
    });
    setOperators(preparedList);
  }

  React.useEffect(() => {
    prepareListOperators(stateFiltersList.operatorsList);
  }, [stateFiltersList.operatorsList]);

  const defaultValue = operators.find(
    (value) => value.operator === defaultInputs.operator,
  );

  return (
    <Autocomplete
      onChange={async (event, value) => {
        dispatchFilters({
          type: 'set_filters',
          filters: { operator: value.operator },
        });
      }}
      isOptionEqualToValue={(option, value) =>
        option.operator === value.operator
      }
      disablePortal
      id="combo-box-operator"
      size="small"
      options={operators}
      defaultValue={defaultValue}
      disableClearable={true}
      renderInput={(params) => (
        <TextField {...params} label="Operator" placeholder="Select operator" />
      )}
    />
  );
}
