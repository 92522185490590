import axios from 'axios';

// const access_token = localStorage.getItem('Authorization')
//   ? JSON.parse(localStorage.getItem('Authorization') || '')
//   : null;
// const headers: { Authorization?: string } = {};
// if (access_token) headers.Authorization = 'Bearer '.concat(access_token);

console.log(
  'process.env.REACT_APP_BASE_ENDPOINT====>',
  process.env.REACT_APP_BASE_ENDPOINT,
);
export default axios.create({
  baseURL:
    process.env.REACT_APP_BASE_ENDPOINT || 'https://staging.api.elo.etldna.com',
});
