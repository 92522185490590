import * as React from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const useStyles = makeStyles(() => {

  return {
    filtersContainer: {
      flex: '0 0 280px',
      display: 'flex',
      flexFlow: 'column',
      padding: '4em 20px',
      backgroundColor: '#F5F5F5',
      height: 'calc(100vh - 64px)',
      position: 'sticky',
      top: 64,
    },
    filtersCloseLabel: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 'auto',
      gap: '0.5em',
      color: '#6A6761',
      textAlign: 'center',
      textTransform: 'uppercase',
      cursor: 'pointer',
      '&:hover': {
        color: '#333',
      },
    },
    filtersContainerClosed: {
      flex: '0 0 30px',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexFlow: 'column',
      padding: '20px 0',
      backgroundColor: '#F5F5F5',
      color: '#6A6761',
      height: 'calc(100vh - 64px)',
      position: 'sticky',
      top: 64,
      cursor: 'pointer',
      '&:hover': {
        background: '#EEE',
      },
    },
    filtersContainerClosedLabel: {
      textTransform: 'uppercase',
      transform: 'rotate(-90deg)',
    },
    contentContainer: {
      flex: '1 1 auto',
      padding: '0px 30px 30px 30px',
    }
  };
});

export default function Logs({filters, content}: {filters :  JSX.Element, content : JSX.Element}) {
  const classes = useStyles();
  const [filterOpen, setFilterOpen] = React.useState(true);

    return (
      <React.Fragment>
        <Box sx={{ display: 'flex' }}>
          <Box
            className={classes.filtersContainer}
            style={{ display: filterOpen ? 'flex' : 'none' }}
          >
            {filters}
            <a
              className={classes.filtersCloseLabel}
              onClick={() => setFilterOpen(false)}
            >
              <CloseIcon fontSize="small" />
              Hide filters
            </a>
          </Box>
          <Box
            onClick={() => setFilterOpen(true)}
            className={classes.filtersContainerClosed}
            style={{ display: filterOpen ? 'none' : 'flex' }}
          >
            <KeyboardArrowRightIcon />
            <div className={classes.filtersContainerClosedLabel}>Filters</div>
            <KeyboardArrowRightIcon />
          </Box>

          <Box className={classes.contentContainer}>
            {content}
          </Box>
        </Box>
        </React.Fragment>
  );
}
