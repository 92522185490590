import React from 'react';
import { ChartData, ScatterDataPoint } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ChartWrapper from './ChartWrapper';
import { useMetrics } from '../../Contexts/metrics-context';
import { getRssiPerSat } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import defaultGraphOptions from './../../Constants/defaultGraphOptions';

export default function ChartRssiPerSat() {
  const { stateMetrics } = useMetrics();
  const [satellite, setSatellite] = React.useState('all');
  const [measureType, setMeasureType] = React.useState('all');

  const rssiProcessedPerSat = React.useMemo(
    () => getRssiPerSat(stateMetrics.metricsOutput, stateMetrics.chartsFormat),
    [stateMetrics.metricsOutput, stateMetrics.chartsFormat],
  );

  const data_ = React.useMemo(() => {
    const data_: ChartData<
      'line',
      (number | ScatterDataPoint | null)[],
      unknown
    > = { datasets: [] };

    Object.keys(rssiProcessedPerSat.data)
      .filter(
        (key) =>
          (satellite === 'all' || key.includes(satellite)) &&
          (measureType === 'all' || key.includes(measureType)),
      )
      .forEach((value, index) => {
        data_.datasets.push({
          type: 'line' as const,
          label: value,
          data: rssiProcessedPerSat.data[value] as any, // eslint-disable-line @typescript-eslint/no-explicit-any
          borderColor: chartColors.lineCharts.borders[index],
          backgroundColor: chartColors.lineCharts.backgrounds[index],
        });
      });

    return data_;
  }, [
    stateMetrics.metricsOutput,
    stateMetrics.chartsFormat,
    satellite,
    measureType,
  ]);

  return (
    <React.Fragment>
      <ChartWrapper title="RSSI per satellite">
        <div>
          <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Satellite</InputLabel>
            <Select
              value={satellite}
              size="small"
              label="Satellite"
              onChange={(e) => setSatellite(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {rssiProcessedPerSat.entities.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 2, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">RSSI Measures</InputLabel>
            <Select
              value={measureType}
              size="small"
              label="Type"
              onChange={(e) => setMeasureType(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {rssiProcessedPerSat.measuresTypes.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box style={{ height: '400px', margin: 'auto' }}>
            <Line options={defaultGraphOptions.line} data={data_} />
          </Box>
        </div>
      </ChartWrapper>
    </React.Fragment>
  );
}
