import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import LayoutWithFilters from './../Layouts/LayoutWithFilters';
import LogsFilters from './../Components/Partner/Logs/LogsFilters';
import GridLogs from './../Components/Partner/Logs/GridLogs';
import { useFilters } from './../Contexts/filters-context';
import { makeMetricsQuery } from './../Utils/util';
import { useFiltersList } from '../Contexts/filtersList-context';
import { getAllLogs, useLogs } from '../Contexts/logs-context';

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      paddingTop: '30px',
    },
    title: {
      color: theme.palette.primary.main,
    },
  };
});

export default function Logs() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const { stateFilters } = useFilters();
  const { stateLogs, dispatchLogs } = useLogs();
  const { dispatchFiltersList } = useFiltersList();

  const handleSubmit = React.useCallback(async () => {
    setLoading(true);
    const query = makeMetricsQuery({
      ...stateFilters.filters,
    });

    try {
      await getAllLogs(dispatchLogs, query, dispatchFiltersList);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, [stateFilters]);

  React.useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <React.Fragment>
      <LayoutWithFilters
        filters={<LogsFilters onSubmit={handleSubmit} disabled={loading} />}
        content={
          <Stack sx={{ width: '100%', height: '100%' }}>
            <Box className={classes.header}>
              <Typography gutterBottom variant="h1" className={classes.title}>
                Logs
              </Typography>
            </Box>
            <GridLogs logs={stateLogs.data} loading={loading} />
          </Stack>
        }
      />
    </React.Fragment>
  );
}
