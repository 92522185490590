import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import { getPacketsPerOperator } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import DoughnutWrapper from './DoughnutWrapper';

export default function DoughnutProcessedOperator({ dataType }: { dataType: string }) {
  const { stateMetrics } = useMetrics();
  const dataChart = getPacketsPerOperator(stateMetrics.metricsOutput, dataType);
  const labels = Object.keys(dataChart);
  const data = Object.values(dataChart);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };

  return (
    <React.Fragment>
      <DoughnutWrapper
        title={"Processed " + dataType + "/Operator"}
        data={chartData}
      />
    </React.Fragment>
  );
}
