import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import chartColors from './../../Constants/colors';
import { Capitalize } from './../../Utils/util';

import DoughnutWrapper from './DoughnutWrapper';

import {
  countMessagesErrors,
  getUknownErrors,
} from '../../Utils/metricsSerializer';

export default function DoughnutProcessedRejected({
  dataType,
}: {
  dataType: string;
}) {
  const { stateMetrics } = useMetrics();

  const dataChart = {
    UNKOWN_OPERATOR: getUknownErrors(stateMetrics.metricsErrors, dataType),
    ALL: countMessagesErrors(stateMetrics.metricsErrors, dataType),
  };

  const errorLabels: { [key: string]: string } = {
    ALL: 'Rejected  ' + Capitalize(dataType),
    UNKOWN_OPERATOR: 'Uknown operator',
  };

  const labels = Object.keys(dataChart).map((error) => errorLabels[error]);
  const data = Object.values(dataChart);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };

  return (
    <React.Fragment>
      <DoughnutWrapper
        title={'Rejected ' + dataType + ' vs unknown'}
        data={chartData}
      />
    </React.Fragment>
  );
}
