import moment from 'moment';
const dateNow = new Date();

const defaultInputs = {
  period: 'currentDay',
  dateRange: [
    moment().startOf('day').format('YYYY-MM-DD'),
    moment().endOf('day').format('YYYY-MM-DD'),
  ],
  month: moment(dateNow).format('MM'),
  operator: '',
  region: '',
  satellite: '',
  typePacket: '',
  devAddress: '',
  year: dateNow.getFullYear().toString(),
  delivery_status: '',
  packet_length: '',
  result_code: '',
};

export default defaultInputs;
