import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

/* eslint-disable @typescript-eslint/no-explicit-any */
const defaultGraphOptions = {
  line: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      },
    },
  },
  doughnut: {
    aspectRatio: 2,
    responsive: true,
    events: [],
    plugins: {
      legend: {
        labels: {
          generateLabels: (chart: any) => {
            const [datasets] = chart.data.datasets;
            return datasets.data.map((data: any, i: number) => ({
              text: `${chart.data.labels[i]} : ${data}`,
              fillStyle: datasets.backgroundColor[i],
              strokeStyle: datasets.backgroundColor[i]
            }))
          }
        }
      }
    }
  },
  bar: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top' as const,
      }
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    }
  }
};

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title
);


export default defaultGraphOptions;
