import React from 'react';
import { ChartData, ScatterDataPoint } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ChartWrapper from './ChartWrapper';
import { useMetrics } from '../../Contexts/metrics-context';
import { getLnsDelay } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import defaultGraphOptions from './../../Constants/defaultGraphOptions';

export default function ChartLnsDelayPerDay() {
  const { stateMetrics } = useMetrics();
  const [satellite, setSatellite] = React.useState('all');
  const [measureType, setMeasureType] = React.useState('all');

  const lnsDelay = React.useMemo(
    () => getLnsDelay(stateMetrics.metricsOutput, stateMetrics.chartsFormat),
    [stateMetrics.metricsOutput, stateMetrics.chartsFormat],
  );

  /*eslint-disable */
  const data_: ChartData<
    'line',
    (number | ScatterDataPoint | null)[],
    unknown
  > = { datasets: [] };

  Object.keys(lnsDelay.data)
    .filter((key) => satellite === 'all' || key.includes(satellite))
    .filter((key) => measureType === 'all' || key.includes(measureType))
    .map((value, index) => {
      data_.datasets.push({
        type: 'line' as const,
        label: value,
        data: lnsDelay.data[value] as any,
        borderColor: chartColors.lineCharts.borders[index],
        backgroundColor: chartColors.lineCharts.backgrounds[index],
      });
    });
  /*eslint-enable */

  const chartsLabel =
    stateMetrics.chartsFormat === 'DD/MM/YYYY'
      ? 'Lns delay (minute(s)/day)'
      : 'Lns delay (minute(s)/hour)';
  return (
    <React.Fragment>
      <ChartWrapper title={chartsLabel}>
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Satellite</InputLabel>
            <Select
              value={satellite}
              size="small"
              label="Satellite"
              onChange={(e) => setSatellite(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {lnsDelay.entities.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small">Delay Type</InputLabel>
            <Select
              value={measureType}
              size="small"
              label="Delay Type"
              onChange={(e) => setMeasureType(e.target.value)}
            >
              <MenuItem value="all">All</MenuItem>
              {lnsDelay.measuresTypes.map((value) => (
                <MenuItem key={value} value={value}>
                  {value}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box
            style={{ height: '400px', margin: 'auto', marginBottom: '50px' }}
          >
            <Line options={defaultGraphOptions.line} data={data_} />
          </Box>
        </div>
      </ChartWrapper>
    </React.Fragment>
  );
}
