import _ from 'lodash';
import { SerializedCountMetric } from '../Types';

export type LastBatches = {
  [key: string]: {
    time_received: string;
    nb_packets: number;
    nb_bytes: number;
    status: string;
    satellite: string;
  };
};

export default function getLastBatches(
  metricsInput: SerializedCountMetric[],
  metricsOutput: SerializedCountMetric[],
) {
  const metricsGroupedById = Object.fromEntries(Object.entries(metricsInput.reduce(
    (
      previousValue: { [key: string]: SerializedCountMetric[] },
      currentValue: SerializedCountMetric,
    ) => {
      const currentId = currentValue.document_id || 'null';
      if (!previousValue[currentId]) previousValue[currentId] = [];
      previousValue[currentId].push(currentValue);
      return previousValue;
    },
    {},
  ))
  .sort((a, b) => new Date(b[1][0].ingested_at || "").getTime() - new Date(a[1][0].ingested_at || "").getTime()))

  const metricsFormatted: LastBatches = {};
  Object.keys(metricsGroupedById).map((id) => {
    if (!metricsFormatted[id])
      metricsFormatted[id] = {
        time_received: '',
        nb_packets: 0,
        nb_bytes: 0,
        status: '',
        satellite: '',
      };
    
    metricsFormatted[id]['time_received'] = metricsGroupedById[id][0].ingested_at || '';

    const totalMsgsofBatch = _.sumBy(metricsGroupedById[id], function (o) {
      return o.messages_count;
    });
    metricsFormatted[id]['nb_packets'] = totalMsgsofBatch;

    const totalPacketsofBatch = _.sumBy(metricsGroupedById[id], function (o) {
      return Number(o.packetLength);
    });
    metricsFormatted[id]['nb_bytes'] = totalPacketsofBatch;
    metricsFormatted[id]['status'] = isBatchProcessed(
      metricsGroupedById[id][0].document_id,
      metricsOutput,
    );

    metricsFormatted[id]['satellite'] = metricsGroupedById[id][0]['satellite'];
  });

  return metricsFormatted;
}

const isBatchProcessed = (
  batch_id: string | undefined,
  metricsOutput: SerializedCountMetric[],
) => {
  const isExist = metricsOutput.find((value) => value.document_id === batch_id);
  return isExist ? 'Processed' : 'Error';
};
