import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMetrics } from '../../Contexts/metrics-context';
import { OperatorsData } from '../../Types';
import moment from 'moment';
import React from 'react';

const columns: GridColDef[] = [
  {
    field: 'operatorName',
    headerName: 'Operator',
    width: 150,
  },
  {
    field: 'Delivered_packets_Today',
    headerName: 'Delivered_packets/Today',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'Delivered_packets_Month',
    headerName: 'Delivered_packets/Since beg month',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'Delivered_packets_Always',
    headerName: 'Delivered_packets/Always',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'Delivered_bytes_Today',
    headerName: 'Delivered_bytes/Today',
    width: 150,
  },
  {
    field: 'Delivered_bytes_Month',
    headerName: 'Delivered_bytes/Since beg month',
    width: 150,
  },

  {
    field: 'Delivered_bytes_Always',
    headerName: 'Delivered_bytes/Always',
    width: 150,
  },
  {
    field: 'Last_delivered_packet',
    headerName: 'Last_delivered_packet',
    width: 150,
  },
  {
    field: 'Undelivered_packets_count',
    headerName: 'Undelivered_packets_count',
    width: 150,
  },
  {
    field: 'Filtered_packets_Today',
    headerName: 'Filtered_packets/Today',
    width: 150,
  },
  {
    field: 'Filtered_packets_Month',
    headerName: 'Filtered_packets/Month',
    width: 150,
  },
  {
    field: 'Filtered_packets_Always',
    headerName: 'Filtered_packets/Always',
    width: 150,
  },
  {
    field: 'last_delivered_event_date',
    headerName: 'last_delivered_event_date',
    width: 150,
  },
  {
    field: 'delay',
    headerName: 'delay',
    width: 150,
  },
];

function prepareRowsData(operatorsData: OperatorsData) {
  const rows: {
    id: number;
    operatorName: string;
    Delivered_packets_Today?: number;
    Delivered_packets_Month?: number;
    Delivered_packets_Always?: number;
    Delivered_bytes_Today?: number;
    Delivered_bytes_Month?: number;
    Delivered_bytes_Always?: number;
    Last_delivered_packet?: string;
    Undelivered_packets_count?: number;
    Filtered_packets_Today?: number;
    Filtered_packets_Month?: number;
    Filtered_packets_Always?: number;
    last_delivered_event_date?: string;
    delay?: string;
  }[] = [];
  
  Object.keys(operatorsData).forEach((operator, index) => {
    rows.push({
      id: index,
      operatorName: operator,
      Delivered_packets_Today:
        operatorsData[operator]?.delivered_packets?.today,
      Delivered_packets_Month:
        operatorsData[operator]?.delivered_packets?.month,
      Delivered_packets_Always:
        operatorsData[operator]?.delivered_packets?.always,
      Delivered_bytes_Today: operatorsData[operator]?.delivered_bytes?.today,
      Delivered_bytes_Month: operatorsData[operator]?.delivered_bytes?.month,
      Delivered_bytes_Always: operatorsData[operator]?.delivered_bytes?.always,
      Last_delivered_packet:
        operatorsData[operator]?.date_last_packet_delivered,
      Undelivered_packets_count: operatorsData[operator]?.undelivered_packets,
      Filtered_packets_Today: operatorsData[operator]?.filtered_packets?.today,
      Filtered_packets_Month: operatorsData[operator]?.filtered_packets?.month,
      Filtered_packets_Always:
        operatorsData[operator]?.filtered_packets?.always,
      last_delivered_event_date: moment(operatorsData[operator]?.last_delivered_event_date).format('DD-MM-YYYY HH:mm:ss'),
      delay: operatorsData[operator]?.delay
    });
  });
  return rows;
}

export default function GridGlobaleDashboardData() {
  const { stateMetrics } = useMetrics();

  const rows = React.useMemo(
    () => prepareRowsData(stateMetrics.metricsDashboardData as OperatorsData),
    [stateMetrics.metricsInput, stateMetrics.metricsOutput],
  );

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
}
