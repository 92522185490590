import * as React from 'react';
import moment from 'moment';
import metricsApi from '../Api/metrics';
import { User } from '../Types';
import { chartsStepOptions } from '../Constants/chartsStepOptions';
import type { filtersListDispatch } from './filtersList-context';

type Action =
  | {
      type: 'get_metrics';
      metricsInput: [];
      metricsOutput: [];
      metricsDashboardData: Record<string, unknown>;
      metricsErrors: [];
      lastRefresh: string;
    }
  | { type: 'get_metrics_loading' }
  | { type: 'finish_loading' }
  | { type: 'set_chartsFormat'; chartsFormat: string }
  | { type: 'set_chartsFormatMode'; chartsFormatMode: string };
type Dispatch = (action: Action) => void;
type State = {
  metricsInput: [];
  metricsOutput: [];
  metricsDashboardData: Record<string, unknown>;
  metricsErrors: [];
  metrics_loaded: boolean;
  lastRefresh: string;
  chartsFormatMode?: string;
  chartsFormat?: string;
};
type MetricsProviderProps = { children: React.ReactNode };

const MetricsStateContext = React.createContext<
  { stateMetrics: State; dispatchMetrics: Dispatch } | undefined
>(undefined);

function metricsReducer(stateMetrics: State, action: Action) {
  switch (action.type) {
    case 'get_metrics': {
      return {
        ...stateMetrics,
        ...action,
        metrics_loaded: true,
      };
    }
    case 'get_metrics_loading': {
      return {
        ...stateMetrics,
        metrics_loaded: false,
      };
    }
    case 'finish_loading': {
      return {
        ...stateMetrics,
        metrics_loaded: true,
      };
    }
    case 'set_chartsFormat': {
      return {
        ...stateMetrics,
        chartsFormat: action.chartsFormat,
      };
    }
    case 'set_chartsFormatMode': {
      return {
        ...stateMetrics,
        chartsFormatMode: action.chartsFormatMode,
      };
    }
    default: {
      throw new Error('Unhandled action');
    }
  }
}

function MetricsProvider({ children }: MetricsProviderProps) {
  const [stateMetrics, dispatchMetrics] = React.useReducer(metricsReducer, {
    metricsInput: [],
    metricsOutput: [],
    metricsDashboardData: {},
    metricsErrors: [],
    metrics_loaded: true,
    lastRefresh: '',
    chartsFormatMode: 'today',
    chartsFormat: chartsStepOptions.today,
  });
  const value = { stateMetrics, dispatchMetrics };
  return (
    <MetricsStateContext.Provider value={value}>
      {children}
    </MetricsStateContext.Provider>
  );
}

function useMetrics() {
  const context = React.useContext(MetricsStateContext);
  if (context === undefined) {
    throw new Error('useMetrics must be used within a MetricsProvider');
  }
  return context;
}

async function getAllMetrics(
  dispatchMetrics: Dispatch,
  dispatchFiltersList: filtersListDispatch,
  queries = '',
) {
  const user = localStorage.getItem('user')
    ? (JSON.parse(localStorage.getItem('user') || '') as User)
    : null;

  dispatchMetrics({ type: 'get_metrics_loading' });
  let metricsInput = [];
  let metricsDashboardData = {};
  let metricsOutput: [] = [];
  let metricsErrors: [] = [];

  try {
    const { data: result } = await metricsApi.getInputMetrics(queries);
    metricsInput = result.data;
    dispatchFiltersList({
      type: 'set_dashboard_filtersList',
      ...result.filterObject,
    });
  } catch (error) {
    console.error('Error getInputMetrics : ', { error });
  }

  metricsOutput = metricsInput.filter(
    (value: { error: string }) =>
      !value.error ||
      value.error === 'null' ||
      value.error === 'DELIVERY_FAILED',
  );

  metricsErrors = metricsInput.filter(
    (value: { error: string }) => value.error && value.error !== 'null',
  );

  try {
    metricsDashboardData =
      user?.role == 'ADMIN'
        ? (await metricsApi.getDashboardData(queries)).data
        : {};
  } catch (error) {
    console.error('Error metricsDashboardData : ', { error });
  }

  dispatchMetrics({ type: 'finish_loading' });

  dispatchMetrics({
    type: 'get_metrics',
    metricsInput: metricsInput,
    metricsOutput: metricsOutput,
    metricsErrors: metricsErrors,
    metricsDashboardData: metricsDashboardData,
    lastRefresh: moment().format('DD/MM, HH:mm'),
  });
}

async function resetMetrics(dispatchMetrics: Dispatch) {
  dispatchMetrics({
    type: 'get_metrics',
    metricsInput: [],
    metricsOutput: [],
    metricsErrors: [],
    metricsDashboardData: {},
    lastRefresh: '',
  });
}

export { MetricsProvider, useMetrics, getAllMetrics, resetMetrics };
