import moment from 'moment';
import _ from 'lodash';

import { SerializedErrorMetric, SerializedCountMetric } from './../Types';

export const lineFormat = (
  metrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  return (
    metrics
      // Sort by Datetimes
      .sort(
        (a, b) =>
          new Date(a?.ts || '').getTime() - new Date(b?.ts || '').getTime(),
      )
      // Metric to Line
      .map((metric) => {
        const dateFormatted = moment(metric.ts).format(
          process.env.REACT_APP_FORMAT_DATE,
        );
        return {
          x: dateFormatted,
          y:
            dataType === 'messages'
              ? metric.messages_count
              : Number(metric.packetLength),
        };
      })
      // Compose Lines with the same X
      .reduce((values: { x: string; y: number }[], value) => {
        const reversedValues = values.slice().reverse();
        const sameXValue = reversedValues.find(({ x }) => x === value.x);

        if (sameXValue) {
          sameXValue.y = sameXValue.y + value.y;
        } else {
          values.push(value);
        }

        return values;
      }, [])
  );
};

export const barFormat = (metrics: SerializedCountMetric[]) => {
  if (!metrics || metrics.length === 0) return [];
  const formattedMetrics = metrics
    .sort(
      (a, b) =>
        new Date(a?.ts || '').getTime() - new Date(b.ts || '').getTime(),
    )
    .map((value) => {
      const currentDate = moment(value.ts);
      return {
        ...value,
        ts: currentDate.format('MMMM, YYYY'),
        packetLength: Number(value.packetLength),
      };
    });

  const groupedMetrics = _(formattedMetrics)
    .groupBy('ts')
    .map((objs, key) => ({
      date: key,
      packetLength: _.sumBy(objs, 'packetLength'),
      messages_count: _.sumBy(objs, 'messages_count'),
    }))
    .value();

  return groupedMetrics;
};

export const doughnutFormat = (
  errorMetrics: SerializedErrorMetric[],
  dataType = 'messages',
) => {
  const errorSums: { [key: string]: number } = errorMetrics.reduce(
    (_errorSums: { [key: string]: number }, errorMetric) => {
      _errorSums[errorMetric.error] =
        (_errorSums[errorMetric?.error] || 0) +
        Number(
          dataType === 'messages'
            ? errorMetric.messages_count
            : errorMetric.packetLength,
        );
      return _errorSums;
    },
    {},
  );

  return {
    data: Object.values(errorSums),
    errors: Object.keys(errorSums),
  };
};

export const totalMessages = (
  metrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  let total = 0;
  metrics.map((value) => {
    total += Number(
      dataType === 'messages' ? value.messages_count : value.packetLength,
    );
  });
  return total;
};

export const countMessagesErrors = (
  errorMetrics: SerializedErrorMetric[],
  dataType = 'messages',
) => {
  const total = errorMetrics.reduce((previousValue, currentValue) => {
    return (previousValue += Number(
      dataType === 'messages'
        ? currentValue.messages_count
        : currentValue.packetLength,
    ));
  }, 0);
  return total;
};

export const totalMessagesErrors = (
  metrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  let total = 0;
  metrics.map((value) => {
    total += Number(
      dataType === 'messages' ? value.messages_count : value.packetLength,
    );
  });

  return total;
};

export const getUknownErrors = (
  errorMetrics: SerializedErrorMetric[],
  dataType = 'messages',
) => {
  const unknownErrors = errorMetrics.filter((value) => {
    if (value.error == 'UNKOWN_OPERATOR') return true;
    else false;
  });
  const totalUnknownErrors = countMessagesErrors(unknownErrors, dataType);
  return totalUnknownErrors;
};

export const getMessagesPerSat = (
  metrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  const sats = metrics.reduce(
    (
      previousValue: { [key: string]: number },
      currentValue: SerializedCountMetric,
    ) => {
      previousValue[currentValue.satellite] =
        (previousValue[currentValue.satellite] || 0) +
        (dataType === 'messages'
          ? currentValue.messages_count
          : Number(currentValue.packetLength));

      return previousValue;
    },
    {},
  );
  return sats;
};

export const getPacketsPerOperator = (
  metrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  const operators = metrics.reduce(
    (
      previousValue: { [key: string]: number },
      currentValue: SerializedCountMetric,
    ) => {
      const index = currentValue.operator ?? 'Not LoraWAN';
      previousValue[index] =
        (previousValue[index] || 0) +
        (dataType === 'messages'
          ? currentValue.messages_count
          : Number(currentValue.packetLength));

      return previousValue;
    },
    {},
  );
  return operators;
};

export const getOperatorsUnsubscribed = (
  metricsInput: SerializedCountMetric[],
  metricsOutput: SerializedCountMetric[],
  dataType = 'messages',
) => {
  const allOperators = getPacketsPerOperator(metricsInput, dataType);
  const subscribedOperators = getPacketsPerOperator(metricsOutput, dataType);

  Object.keys(subscribedOperators).map((value) => {
    delete allOperators[value];
  });
  delete allOperators['null'];

  return allOperators;
};

export const getPacketsProcessedRejectedPerSat = (
  metricsOutput: SerializedCountMetric[],
  errorMetrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  const packetsProcessedPerSat = getMessagesPerSat(metricsOutput, dataType);
  const packetsRejectedPerSat = getMessagesPerSat(errorMetrics, dataType);
  const sats = [
    ...Object.keys(packetsProcessedPerSat),
    ...Object.keys(packetsRejectedPerSat),
  ];
  const satsUnique = Array.from(new Set(sats));
  return { packetsProcessedPerSat, packetsRejectedPerSat, satsUnique };
};

export const getPacketsProcessedRejectedPerOperator = (
  metricsOutput: SerializedCountMetric[],
  errorMetrics: SerializedCountMetric[],
  dataType = 'messages',
) => {
  const packetsProcessedPerOperator = getPacketsPerOperator(
    metricsOutput,
    dataType,
  );
  const packetsRejectedPerOperator = getPacketsPerOperator(
    errorMetrics,
    dataType,
  );
  const operators = [
    ...Object.keys(packetsProcessedPerOperator),
    ...Object.keys(packetsRejectedPerOperator),
  ];
  const operatorsUnique = Array.from(new Set(operators));
  return {
    packetsProcessedPerOperator,
    packetsRejectedPerOperator,
    operatorsUnique,
  };
};

export const getSnrPerSat = (
  metricsOutput: SerializedCountMetric[],
  chartsFormat?: string,
) => {
  // Get Snr per Sat
  const snrPerSat = metricsOutput.reduce(
    (
      previousValue: { [key: string]: { [key: string]: number[] } },
      currentValue: SerializedCountMetric,
    ) => {
      const currentDate =
        moment(currentValue.ts).format(chartsFormat || 'DD/MM/YYYY') || 'null';
      if (!previousValue[currentValue.satellite]) {
        previousValue[currentValue.satellite] = {};
      }
      if (!previousValue[currentValue.satellite][currentDate]) {
        previousValue[currentValue.satellite][currentDate] = [];
      }

      previousValue[currentValue.satellite][currentDate].push(
        Number(currentValue.snr),
      );

      return previousValue;
    },
    {},
  );
  const minMaxAvg = calculateMinMaxAvg(snrPerSat);
  const formattedDataForChart = formatMinMaxAvgToChart(minMaxAvg);
  return {
    data: formattedDataForChart,
    entities: Object.keys(minMaxAvg[Object.keys(minMaxAvg)[0]]),
    measuresTypes: ['min', 'max', 'avg'],
  };
};

export const getObjectsPerDate = (
  metricsOutput: SerializedCountMetric[],
  chartsFormat?: string,
) => {
  metricsOutput.sort(function (a, b) {
    return new Date(a.ts || '').getTime() - new Date(b?.ts || '').getTime();
  });

  // Get Snr per Sat
  const nbOperatorsPerDate = metricsOutput.reduce(
    (
      previousValue: { [key: string]: SerializedCountMetric[] },
      currentValue: SerializedCountMetric,
    ) => {
      const currentOperator = currentValue.operator || 'null';

      if (!previousValue[currentOperator]) {
        previousValue[currentOperator] = [];
      }

      previousValue[currentOperator].push(currentValue);

      return previousValue;
    },
    {},
  );

  const nbOfOperators = {};
  Object.keys(nbOperatorsPerDate).map((value) => {
    return nbOperatorsPerDate[value].map((currentValue) => {
      const currentDate =
        moment(currentValue?.ts).format(chartsFormat || 'DD/MM/YYYY') || 'null';
      if (!nbOfOperators[value]) {
        nbOfOperators[value] = {};
      }
      if (!nbOfOperators[value][currentDate]) {
        nbOfOperators[value][currentDate] = [];
      }
      nbOfOperators[value][currentDate].push(currentValue);
      return nbOfOperators;
    });
  });

  const nbOfOperatorsPerObject = {};
  Object.keys(nbOfOperators).map((value) => {
    Object.keys(nbOfOperators[value]).map((value_2) => {
      return Object.keys(nbOfOperators[value][value_2]).map((currentValue) => {
        const currentDevaddr =
          nbOfOperators[value][value_2][currentValue]?.dev_addr || 'null';
        if (!nbOfOperatorsPerObject[value]) {
          nbOfOperatorsPerObject[value] = {};
        }
        if (!nbOfOperatorsPerObject[value][value_2]) {
          nbOfOperatorsPerObject[value][value_2] = {};
        }
        if (!nbOfOperatorsPerObject[value][value_2][currentDevaddr]) {
          nbOfOperatorsPerObject[value][value_2][currentDevaddr] = [];
        }

        nbOfOperatorsPerObject[value][value_2][currentDevaddr].push(
          nbOfOperators[value][value_2][currentValue]?.dev_addr,
        );
        return nbOfOperatorsPerObject;
      });
    });
  });

  const countDates = {};
  Object.keys(nbOfOperatorsPerObject).map((value) => {
    Object.keys(nbOfOperatorsPerObject[value]).map((value2) => {
      return Object.keys(nbOfOperatorsPerObject[value][value2]).map(() => {
        if (!countDates[value]) countDates[value] = {};

        if (!countDates[value][value2]) countDates[value][value2] = {};

        countDates[value][value2] = Object.keys(
          nbOfOperatorsPerObject[value][value2],
        ).length;
      });
    });
  });
  return countDates;
};

export const getSnrPerOperator = (
  metricsOutput: SerializedCountMetric[],
  chartsFormat?: string,
) => {
  // Get Snr per Operator
  const snrPerOperator = metricsOutput.reduce(
    (
      previousValue: { [key: string]: { [key: string]: number[] } },
      currentValue: SerializedCountMetric,
    ) => {
      const currentDate =
        moment(currentValue.ts).format(chartsFormat || 'DD/MM/YYYY') || 'null';
      const currentOperator = currentValue.operator || 'Not LoraWAN';
      if (!previousValue[currentOperator]) {
        previousValue[currentOperator] = {};
      }
      if (!previousValue[currentOperator][currentDate]) {
        previousValue[currentOperator][currentDate] = [];
      }

      previousValue[currentOperator][currentDate].push(
        Number(currentValue.snr),
      );

      return previousValue;
    },
    {},
  );

  const minMaxAvg = calculateMinMaxAvg(snrPerOperator);
  const formattedDataForChart = formatMinMaxAvgToChart(minMaxAvg);
  return {
    data: formattedDataForChart,
    entities: Object.keys(minMaxAvg[Object.keys(minMaxAvg)[0]]),
    measuresTypes: ['min', 'max', 'avg'],
  };
};

export const getRssiPerSat = (
  metricsOutput: SerializedCountMetric[],
  chartsFormat?: string,
) => {
  // Get Snr per Sat
  const rssiPerSat = metricsOutput.reduce(
    (
      previousValue: { [key: string]: { [key: string]: number[] } },
      currentValue: SerializedCountMetric,
    ) => {
      const currentDate =
        moment(currentValue.ts).format(chartsFormat || 'DD/MM/YYYY') || 'null';
      if (!previousValue[currentValue.satellite]) {
        previousValue[currentValue.satellite] = {};
      }
      if (!previousValue[currentValue.satellite][currentDate]) {
        previousValue[currentValue.satellite][currentDate] = [];
      }

      previousValue[currentValue.satellite][currentDate].push(
        Number(currentValue.rssi),
      );

      return previousValue;
    },
    {},
  );

  const minMaxAvg = calculateMinMaxAvg(rssiPerSat);
  const formattedDataForChart = formatMinMaxAvgToChart(minMaxAvg);
  return {
    data: formattedDataForChart,
    entities: Object.keys(minMaxAvg[Object.keys(minMaxAvg)[0]]),
    measuresTypes: ['min', 'max', 'avg'],
  };
};

export const getRssiPerOperator = (
  metricsOutput: SerializedCountMetric[],
  chartsFormat?: string,
) => {
  // Get Rssi per Operator
  const rssiPerOperator = metricsOutput.reduce(
    (
      previousValue: { [key: string]: { [key: string]: number[] } },
      currentValue: SerializedCountMetric,
    ) => {
      const currentDate =
        moment(currentValue.ts).format(chartsFormat || 'DD/MM/YYYY') || 'null';
      const currentOperator = currentValue.operator || 'Not LoraWAN';
      if (!previousValue[currentOperator]) {
        previousValue[currentOperator] = {};
      }
      if (!previousValue[currentOperator][currentDate]) {
        previousValue[currentOperator][currentDate] = [];
      }

      previousValue[currentOperator][currentDate].push(
        Number(currentValue.rssi),
      );

      return previousValue;
    },
    {},
  );

  const minMaxAvg = calculateMinMaxAvg(rssiPerOperator);
  const formattedDataForChart = formatMinMaxAvgToChart(minMaxAvg);
  return {
    data: formattedDataForChart,
    entities: Object.keys(minMaxAvg[Object.keys(minMaxAvg)[0]]),
    measuresTypes: ['min', 'max', 'avg'],
  };
};

type MinMaxAvg = {
  min: {
    [key: string]: {
      [key: string]: number | number[] | undefined;
    };
  };
  max: {
    [key: string]: {
      [key: string]: number | number[] | undefined;
    };
  };
  avg: {
    [key: string]: {
      [key: string]: number | number[] | undefined;
    };
  };
};

/**
 * Prepare MinMaxAvg data to chart
 * @param MinMaxAvg
 * @returns
 */
const formatMinMaxAvgToChart = (MinMaxAvg: MinMaxAvg) => {
  const result: {
    [key: string]: { x: string; y: number | number[] | undefined }[];
  } = {};

  (Object.keys(MinMaxAvg) as Array<keyof typeof MinMaxAvg>).map(
    (measureType) => {
      Object.keys(MinMaxAvg[measureType]).map((name) => {
        Object.keys(MinMaxAvg[measureType][name]).map((date) => {
          const nameAndType = `${measureType} ${name}`;
          if (!result[nameAndType]) result[nameAndType] = [];
          result[nameAndType].push({
            x: date,
            y: MinMaxAvg[measureType][name][date],
          });
        });
      });
    },
  );

  return result;
};

const calculateMinMaxAvg = (lnsDelays: {
  [key: string]: {
    [key: string]: number[];
  };
}) => {
  const min: {
    [key: string]: {
      [key: string]: number[] | number | undefined;
    };
  } = {};
  const max: {
    [key: string]: {
      [key: string]: number[] | number | undefined;
    };
  } = {};
  const avg: {
    [key: string]: {
      [key: string]: number[] | number | undefined;
    };
  } = {};
  Object.keys(lnsDelays).map((sat) => {
    return Object.keys(lnsDelays[sat]).map((date) => {
      // minDelays
      if (!min[sat]) min[sat] = {};
      if (!min[sat][date]) min[sat][date] = [];
      min[sat][date] = _.min(lnsDelays[sat][date]);

      //maxDelays
      if (!max[sat]) max[sat] = {};
      if (!max[sat][date]) max[sat][date] = [];
      max[sat][date] = _.max(lnsDelays[sat][date]);

      //avgDelays
      if (!avg[sat]) avg[sat] = {};
      if (!avg[sat][date]) avg[sat][date] = [];
      avg[sat][date] = _.mean(lnsDelays[sat][date]);
    });
  });
  return { min, avg, max };
};

export const getLnsDelay = (
  metricsOutput: SerializedCountMetric[],
  chartsFormat?: string,
) => {
  const lnsDelayTime = metricsOutput.map((value) => {
    const timestamp = moment(value.ts);
    const outputAt = moment(value.output_at);
    const delay = outputAt.diff(timestamp, 'minutes');
    return { ...value, delay };
  });

  const lnsDelay = lnsDelayTime.reduce(
    (
      previousValue: { [key: string]: { [key: string]: number[] } },
      currentValue,
    ) => {
      const currentDate =
        moment(currentValue.ts).format(chartsFormat || 'DD/MM/YYYY') || 'null';

      if (!previousValue[currentValue.satellite]) {
        previousValue[currentValue.satellite] = {};
      }
      if (!previousValue[currentValue.satellite][currentDate]) {
        previousValue[currentValue.satellite][currentDate] = [];
      }

      previousValue[currentValue.satellite][currentDate].push(
        currentValue?.delay,
      );

      // if (currentValue?.delay === 0) console.log(currentValue);

      return previousValue;
    },
    {},
  );
  const minMaxAvg = calculateMinMaxAvg(lnsDelay);
  const formattedDataForChart = formatMinMaxAvgToChart(minMaxAvg);

  return {
    data: formattedDataForChart,
    entities: Object.keys(minMaxAvg[Object.keys(minMaxAvg)[0]]),
    measuresTypes: ['min', 'max', 'avg'],
  };
};

export const getNumberOfActiveObjects = (
  metricsInput: SerializedCountMetric[],
) => {
  const uniqDevAddr = metricsInput.reduce((prev: string[], curr) => {
    const currDevAddr = curr?.dev_addr || '';
    if (!prev.find((element) => element === currDevAddr)) {
      prev.push(currDevAddr);
    }
    return prev;
  }, []);

  return uniqDevAddr.length;
};
