import * as React from 'react';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import BarChartIcon from '@mui/icons-material/BarChart';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { colors, rgba } from './../../Constants/colors';

export default function CardTotal({
  title,
  total,
  color = 'rgb(4, 41, 122)',
  backgroundColor = 'rgb(208, 242, 255)',
  icon = <BarChartIcon width={24} height={24} />,
  iconColor = 'rgb(12, 83, 183)',
  selectedModelIndex,
}: {
  title: string;
  total: string | number;
  color?: string;
  backgroundColor?: string;
  icon?: JSX.Element;
  iconColor?: string;
  selectedModelIndex?: number;
}) {
  const modelPredifined = [
    {
      color: '#FFF',
      backgroundColor: rgba(colors.blue, 1),
      iconColor: '#FFF',
      icon: <QueryStatsIcon width={24} height={24} />,
    },
    {
      color: '#FFF',
      backgroundColor: rgba(colors.yellow, 1),
      iconColor: '#FFF',
      icon: <BarChartIcon width={24} height={24} />,
    },
    {
      color: '#FFF',
      backgroundColor: rgba(colors.green, 1),
      iconColor: '#FFF',
      icon: <BarChartIcon width={24} height={24} />,
    }
  ];

  let modelToUse: {
    color?: string;
    backgroundColor?: string;
    icon?: JSX.Element;
    iconColor?: string;
  };

  if (selectedModelIndex === undefined) {
    modelToUse = {
      color,
      backgroundColor,
      iconColor,
      icon,
    };
  } else {
    modelToUse = modelPredifined[selectedModelIndex];
  }

  const RootStyle = styled(Card)(({ theme }) => ({
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(5, 0),
    color: modelToUse.color,
    backgroundColor: modelToUse.backgroundColor,
  }));
  const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(8),
    height: theme.spacing(8),
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    color: modelToUse.iconColor,
    backgroundImage: `linear-gradient(135deg, ${alpha(
      modelToUse.backgroundColor as string,
      0,
    )} 0%, ${alpha(iconColor, 0.24)} 100%)`,
  }));
  return (
    <RootStyle>
      <IconWrapperStyle>{modelToUse.icon}</IconWrapperStyle>
      <Typography variant="h3">{total}</Typography>
      <Typography variant="subtitle2">
        {title}
      </Typography>
    </RootStyle>
  );
}
