import React from 'react';
import { useLogs } from '../../Contexts/logs-context';

import { useMetrics } from '../../Contexts/metrics-context';
import { statusLogsDoughnut } from '../../Utils/logs';
import chartColors from './../../Constants/colors';
import DoughnutWrapper from './DoughnutWrapper';

export default function DoughnutLogsStatus() {
  const { stateMetrics } = useMetrics();
  const { stateLogs } = useLogs();

  const dataChart = React.useMemo(
    () => statusLogsDoughnut(stateLogs.data),
    [stateLogs.data, stateMetrics.chartsFormat],
  );
  const labels = Object.keys(dataChart);
  const data = Object.values(dataChart);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };

  return (
    <React.Fragment>
      <DoughnutWrapper title={'Status counter'} data={chartData} />
    </React.Fragment>
  );
}
