import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import defaultInputs from '../../Constants/defaultInputs';
import { useFilters } from '../../Contexts/filters-context';
import { useFiltersList } from '../../Contexts/filtersList-context';

export default function SatelliteInput() {
  const { dispatchFilters } = useFilters();
  const { stateFiltersList } = useFiltersList();
  const firstValue = { label: 'Any satellite', satellite: '' };
  const [satellites, setSatellites] = React.useState<
    { label: string; satellite: string }[]
  >([firstValue]);

  function prepareListSatellites(satellitesFromServer: string[]) {
    const preparedList = [firstValue];
    satellitesFromServer.map((element) => {
      preparedList.push({
        label: element,
        satellite: element,
      });
    });
    setSatellites(preparedList);
  }

  React.useEffect(() => {
    prepareListSatellites(stateFiltersList.satellitesList);
  }, [stateFiltersList]);

  const defaultValue = satellites.find(
    (value) => value.satellite === defaultInputs.satellite,
  );

  return (
    <Autocomplete
      onChange={async (event, value) => {
        dispatchFilters({
          type: 'set_filters',
          filters: { satellite: value.satellite },
        });
      }}
      isOptionEqualToValue={(option, value) =>
        option.satellite === value.satellite
      }
      disablePortal
      id="combo-box-satellite"
      size="small"
      options={satellites}
      defaultValue={defaultValue}
      disableClearable={true}
      renderInput={(params) => (
        <TextField {...params} label="Satellite" placeholder="Select region" />
      )}
    />
  );
}
