import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMetrics } from '../../Contexts/metrics-context';
import getLastBatches, { LastBatches } from '../../Utils/lastBatches';
import moment from 'moment';

const columns: GridColDef[] = [
  {
    field: 'id_batch',
    headerName: 'id batch',
    width: 150,
  },
  {
    field: 'nb_packets',
    headerName: 'nb_packets',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'nb_bytes',
    headerName: 'nb_bytes',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'status',
    headerName: 'status',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'satellite',
    headerName: 'satellite',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'time_received',
    headerName: 'time_received',
    minWidth: 150,
    width: 200,
  },
];

// lastBatches
function prepareRowsData(batches: LastBatches) {
  const rows: {
    id: number;
    id_batch: string;
    nb_packets?: number;
    nb_bytes: number;
    status: string;
    satellite: string;
    time_received: string;
  }[] = [];
  
  Object.keys(batches).forEach((id_batch, index) => {
    rows.push({
      id: index,
      id_batch: id_batch,
      nb_packets: batches[id_batch].nb_packets,
      nb_bytes: batches[id_batch].nb_bytes,
      status: batches[id_batch].status,
      satellite: batches[id_batch].satellite,
      time_received: moment(batches[id_batch].time_received).format('DD-MM-YYYY HH:mm:ss')
    });
  });
  return rows;
}

export default function GridLastBatches() {
  const { stateMetrics } = useMetrics();

  const rows = React.useMemo(
    () => {
      const batchesData = getLastBatches(stateMetrics.metricsInput, stateMetrics.metricsOutput);
      return prepareRowsData(batchesData);
    },
    [stateMetrics.metricsInput, stateMetrics.metricsOutput],
  );

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
}
