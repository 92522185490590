import * as React from 'react';
import { Routes, Route, Link } from 'react-router-dom';

import LayoutTracking from '../Layouts/LayoutTracking';
import HomeTracking from './../Screens/HomeTracking';

function Stats() {
  return (
    <div>
      <h2>Stats</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default function TrackingRoutes() {
  return (
    <Routes>
      <Route path="/" element={<LayoutTracking />}>
        <Route index element={<HomeTracking />} />
        <Route path="stats" element={<Stats />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
