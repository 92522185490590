import React from 'react';
import { Bar } from 'react-chartjs-2';
import Box from '@mui/material/Box';

import { useMetrics } from '../../Contexts/metrics-context';
import { getPacketsProcessedRejectedPerSat } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import defaultGraphOptions from './../../Constants/defaultGraphOptions';
import ChartWrapper from './ChartWrapper';
import { Capitalize } from '../../Utils/util';

export default function ChartPacketsProcessedRejectedPerSat({
  dataType,
}: {
  dataType: string;
}) {
  const { stateMetrics } = useMetrics();
  const packetsProcessedRejectedPerSat = getPacketsProcessedRejectedPerSat(
    stateMetrics.metricsOutput,
    stateMetrics.metricsErrors,
    dataType,
  );

  const labelsChart = packetsProcessedRejectedPerSat.satsUnique;

  const dataMonthsProp = {
    labels: labelsChart,
    datasets: [
      {
        label: 'Processed',
        data: labelsChart.map(
          (value) =>
            packetsProcessedRejectedPerSat.packetsProcessedPerSat[value],
        ),
        backgroundColor: chartColors.barCharts.backgrounds[0],
      },
      {
        label: 'Rejected',
        data: labelsChart.map(
          (value) =>
            packetsProcessedRejectedPerSat.packetsRejectedPerSat[value],
        ),
        backgroundColor: chartColors.barCharts.backgrounds[1],
      },
    ],
  };

  return (
    <React.Fragment>
      <ChartWrapper title={Capitalize(dataType) + ' per satellite'}>
        <Box style={{ height: '400px', margin: 'auto' }}>
          <Bar options={defaultGraphOptions.bar} data={dataMonthsProp} />
        </Box>
      </ChartWrapper>
    </React.Fragment>
  );
}
