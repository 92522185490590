import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import { getMessagesPerSat } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import DoughnutWrapper from './DoughnutWrapper';

export default function DoughnutProcessedSat({ dataType }: { dataType: string }) {
  const { stateMetrics } = useMetrics();
  const dataChart = getMessagesPerSat(stateMetrics.metricsOutput, dataType);
  const labels = Object.keys(dataChart);
  const data = Object.values(dataChart);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };

  return (
    <React.Fragment>
      <DoughnutWrapper
        title={"Processed "+ dataType + "/Sat"}
        data={chartData}
      />
    </React.Fragment>
  );
}
