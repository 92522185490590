import * as React from 'react';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { Theme } from '@mui/material/styles';

import OperatorsInput from './OperatorsInput';
import SatelliteInput from './SatelliteInput';
import DevAddressInput from './DevAddressInput';
import { getAllMetrics, useMetrics } from '../../Contexts/metrics-context';
import { makeMetricsQuery } from '../../Utils/util';
import { useFilters } from '../../Contexts/filters-context';
import DateRangeInput from './DateRangeInput';
import { useFiltersList } from '../../Contexts/filtersList-context';
import { chartsStepOptions } from '../../Constants/chartsStepOptions';
import { getAllLogs, useLogs } from '../../Contexts/logs-context';
import PermissionsGate from '../../Utils/permission/PermissionsGate';
import { ROLES } from '../../Utils/permission/permission-maps';

const useStyles = makeStyles((theme: Theme) => ({
  inputs: {
    margin: '25px 0',
  },
  headers: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
    color: theme.palette.primary.main,
  },
}));

export default function DashboardFilters() {
  const classes = useStyles();
  const { stateMetrics, dispatchMetrics } = useMetrics();
  const { dispatchLogs } = useLogs();
  const { stateFilters, dispatchFilters } = useFilters();
  const { dispatchFiltersList } = useFiltersList();

  React.useEffect(() => {
    return () => {
      dispatchFilters({
        type: 'reset_filters',
      });
    };
  }, []);

  async function refreshData() {
    const query = makeMetricsQuery({
      ...stateFilters.filters,
    });
    await getAllMetrics(dispatchMetrics, dispatchFiltersList, query);
    await getAllLogs(dispatchLogs, query, dispatchFiltersList);
    if (stateMetrics.chartsFormatMode) {
      dispatchMetrics({
        type: 'set_chartsFormat',
        chartsFormat: chartsStepOptions[stateMetrics.chartsFormatMode],
      });
    }
  }

  return (
    <>
      <Box>
        <div className={classes.headers}>Filters: </div>
        <Box component="div" className={classes.inputs}>
          <DateRangeInput />
        </Box>
        <PermissionsGate roles={[ROLES.admin]}>
          <Box component="div" className={classes.inputs}>
            <OperatorsInput />
          </Box>
        </PermissionsGate>
        <Box component="div" className={classes.inputs}>
          <SatelliteInput />
        </Box>
        <Box component="div" className={classes.inputs}>
          <DevAddressInput />
        </Box>
        <Box component="div" className={classes.inputs}>
          <Button
            fullWidth
            variant="contained"
            disabled={!stateMetrics.metrics_loaded}
            onClick={async () => {
              await refreshData();
            }}
          >
            Run Filter
          </Button>
        </Box>
      </Box>
    </>
  );
}
