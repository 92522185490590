import * as React from 'react';
import moment from 'moment';
import { getLogs } from '../Api/logs';
import { filtersListDispatch, updateFiltersList } from './filtersList-context';
import { FilterObject } from '../Types';

type Action =
  | {
      type: 'get_logs';
      data: [];
      filterObject: FilterObject;
      lastRefresh: string;
    }
  | { type: 'get_logs_loading' }
  | { type: 'finish_loading' };
type Dispatch = (action: Action) => void;
type State = {
  data: [];
  filterObject: FilterObject;
  logs_loaded: boolean;
  lastRefresh: string;
};
type LogsProviderProps = { children: React.ReactNode };

const LogsStateContext = React.createContext<
  { stateLogs: State; dispatchLogs: Dispatch } | undefined
>(undefined);

function logsReducer(stateLogs: State, action: Action) {
  switch (action.type) {
    case 'get_logs': {
      return {
        ...stateLogs,
        ...action,
        logs_loaded: true,
      };
    }
    case 'get_logs_loading': {
      return {
        ...stateLogs,
        logs_loaded: false,
      };
    }
    case 'finish_loading': {
      return {
        ...stateLogs,
        logs_loaded: true,
      };
    }
    default: {
      throw new Error('Unhandled action');
    }
  }
}

function LogsProvider({ children }: LogsProviderProps) {
  const [stateLogs, dispatchLogs] = React.useReducer(logsReducer, {
    data: [],
    filterObject: {
      devAddrsList: [],
      operatorsList: [],
      regionsList: [],
      satellitesList: [],
      typesList: [],
      statusList: [],
    },
    logs_loaded: true,
    lastRefresh: '',
  });
  const value = { stateLogs, dispatchLogs };
  return (
    <LogsStateContext.Provider value={value}>
      {children}
    </LogsStateContext.Provider>
  );
}

function useLogs() {
  const context = React.useContext(LogsStateContext);
  if (context === undefined) {
    throw new Error('useLogs must be used within a LogsProvider');
  }
  return context;
}

async function getAllLogs(
  dispatchLogs: Dispatch,
  queries = '',
  dispatchFiltersList: filtersListDispatch,
) {
  dispatchLogs({ type: 'get_logs_loading' });
  let logs = [];
  let filterObject = [];

  try {
    const { data } = await getLogs(queries);
    logs = data.logs;
    filterObject = data.filterObject;
    updateFiltersList(dispatchFiltersList, filterObject);
  } catch (error) {
    console.error('Error getLogs : ', { error });
  }

  dispatchLogs({ type: 'finish_loading' });

  dispatchLogs({
    type: 'get_logs',
    data: logs,
    filterObject,
    lastRefresh: moment().format('DD/MM, HH:mm'),
  });
}

async function resetLogs(dispatchLogs: Dispatch) {
  dispatchLogs({
    type: 'get_logs',
    data: [],
    filterObject: {
      devAddrsList: [],
      operatorsList: [],
      regionsList: [],
      satellitesList: [],
      typesList: [],
      statusList: [],
    },
    lastRefresh: '',
  });
}

export { LogsProvider, useLogs, getAllLogs, resetLogs };
