import * as React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CardContent from '@mui/material/CardContent';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import ChartMessagePerDay from '../Components/Partner/ChartMessagesPerDay';
import ChartMessagesStatus from '../Components/Partner/ChartMessagesStatus';
import DoughnutLogsStatus from '../Components/Partner/DoughnutLogsStatus';
import DoughnutProcessedRejected from '../Components/Partner/DoughnutProcessedRejected';
import DoughnutProcessedOperator from '../Components/Partner/DoughnutProcessedOperator';
import DoughnutRejectedUknown from '../Components/Partner/DoughnutRejectedUknown';
import DoughnutUnsubscribedOperators from '../Components/Partner/DoughnutUnsubscribedOperators';
import DoughnutProcessedSat from '../Components/Partner/DoughnutProcessedSat';
import DoughnutRejectedSat from '../Components/Partner/DoughnutRejectedSat';
import ChartMessagesPerMonth from '../Components/Partner/ChartMessagesPerMonth';
import ChartPacketsProcessedRejectedPerSat from '../Components/Partner/ChartPacketsProcessedRejectedPerSat';
import ChartSnrPerSat from '../Components/Partner/ChartSnrPerSat';
import ChartSnrPerOperator from '../Components/Partner/ChartSnrPerOperator';
import ChartRssiPerSat from '../Components/Partner/ChartRssiPerSat';
import ChartRssiPerOperator from '../Components/Partner/ChartRssiPerOperator';
import ChartLnsDelayPerDay from '../Components/Partner/ChartLnsDelayPerDay';
import CardTotal from '../Components/Partner/CardTotal';
import GridSatelliteData from '../Components/Partner/GridSatelliteData';
import GridGlobaleDashboardData from '../Components/Partner/GridGlobaleDashboardData';
import GridLastBatches from '../Components/Partner/GridLastBatches';
import DashboardFilters from '../Components/Partner/DashboardFilters';
import TabPanel from '../Components/TabPannel';
import LinearProgress from '@mui/material/LinearProgress';

import {
  getNumberOfActiveObjects,
  totalMessages,
  totalMessagesErrors,
} from '../Utils/metricsSerializer';
import { getAllMetrics, useMetrics } from '../Contexts/metrics-context';
import { useFilters } from '../Contexts/filters-context';
import ChartPacketsProcessedRejectedPerOperator from '../Components/Partner/ChartPacketsProcessedRejectedPerOperator';
import PermissionsGate from '../Utils/permission/PermissionsGate';
import { ROLES } from '../Utils/permission/permission-maps';
import LayoutWithFilters from './../Layouts/LayoutWithFilters';
import { useFiltersList } from '../Contexts/filtersList-context';
import { makeMetricsQuery } from '../Utils/util';
import { getAllLogs, useLogs } from '../Contexts/logs-context';
import ChartObjectsPerDate from '../Components/Partner/ChartObjectsPerDate';
import ChartStatusPerDate from '../Components/Partner/ChartStatusPerDate';

export const optionsMonths = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Messages per Months',
    },
  },
};

const dataOfMonths: { [key: string]: number } = {
  January: 520,
  February: 450,
  March: 742,
  April: 325,
  May: 71,
  June: 985,
  July: 213,
};

const getLabels = (): string[] => {
  return Object.keys(dataOfMonths);
};

export const dataMonthsProp = {
  labels: getLabels(),
  datasets: [
    {
      label: 'Months',
      data: getLabels().map((value) => dataOfMonths[value]),
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
  ],
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '30px',
    },
    title: {
      color: theme.palette.primary.main,
    },
    circleContainer: {
      display: 'inline-block',
      lineHeight: '0px',
      borderRadius: '50%',
      border: '2px solid #55efc4',
      fontSize: '32px',
    },
    numberCircle: {
      display: 'inline-block',
      paddingTop: '50%',
      paddingBottom: '50%',
      marginLeft: '8px',
      marginRight: '8px',
    },
  };
});

export default function HomePartner() {
  const { dispatchFiltersList } = useFiltersList();
  const { dispatchLogs } = useLogs();
  const { stateMetrics, dispatchMetrics } = useMetrics();
  const { stateFilters } = useFilters();
  const classes = useStyles();
  const [currentTabIndex, setCurrentTabIndex] = React.useState(0);
  const [dataType, setDataType] = React.useState('messages');
  const totalInput = totalMessages(stateMetrics.metricsInput, dataType);
  const totalOutput = totalMessages(stateMetrics.metricsOutput, dataType);
  const nbActiveObjects = getNumberOfActiveObjects(stateMetrics.metricsOutput);
  const totalMsgErrors = totalMessagesErrors(
    stateMetrics.metricsErrors,
    dataType,
  );

  React.useEffect(() => {
    async function refreshData() {
      /* await getAllFiltersList(dispatchFiltersList); */
      const query = makeMetricsQuery({
        ...stateFilters.filters,
      });
      await getAllMetrics(dispatchMetrics, dispatchFiltersList, query);
      await getAllLogs(dispatchLogs, query, dispatchFiltersList);
    }
    refreshData();
  }, []);

  return (
    <React.Fragment>
      <LayoutWithFilters
        filters={<DashboardFilters />}
        content={
          <>
            <Box
              sx={{
                position: 'sticky',
                top: '64px', // topbar height
                backgroundColor: 'white',
                zIndex: 10,
              }}
            >
              <div className={classes.header}>
                <Typography gutterBottom variant="h1" className={classes.title}>
                  Dashboard
                </Typography>
                <div>
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">
                      Data type
                    </InputLabel>
                    <Select
                      value={dataType}
                      size="small"
                      label="Data type"
                      onChange={(e) => setDataType(e.target.value)}
                    >
                      <MenuItem value="messages">Messages</MenuItem>
                      <MenuItem value="bytes">Bytes</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              {stateMetrics.metrics_loaded && stateMetrics.lastRefresh && (
                <Typography
                  gutterBottom
                  variant="h6"
                  className={classes.title}
                  sx={{ pl: 0.5 }}
                >
                  last refresh : {stateMetrics.lastRefresh}
                </Typography>
              )}
              {!stateMetrics.metrics_loaded && (
                <LinearProgress color="inherit" />
              )}
              <Tabs
                value={currentTabIndex}
                onChange={(e, i) => setCurrentTabIndex(i)}
                aria-label="basic tabs example"
              >
                <Tab label="Item One" />
                <Tab label="Item Two" />
                <Tab label="Item Three" />
              </Tabs>
            </Box>

            <Box>
              <div>
                <TabPanel value={currentTabIndex} index={0}>
                  <Grid
                    container
                    alignItems="stretch"
                    marginBottom={4}
                    spacing={1}
                  >
                    <Grid item lg={2}>
                      <CardTotal
                        title={'Input ' + dataType}
                        total={totalInput}
                        selectedModelIndex={0}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      <CardTotal
                        title={'Output ' + dataType}
                        total={totalOutput}
                        selectedModelIndex={1}
                      />
                    </Grid>
                    <Grid item lg={2}>
                      {/* TODO  */}
                      <CardTotal
                        title="Number of active objects"
                        total={nbActiveObjects}
                        selectedModelIndex={2}
                      />
                    </Grid>
                    <Grid item lg={6}>
                      <Card
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          height: '100%',
                        }}
                      >
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                          <CardContent sx={{ flex: '1 0 auto' }}>
                            <span className={classes.circleContainer}>
                              <span className={classes.numberCircle}>
                                {totalMsgErrors}
                              </span>
                            </span>
                          </CardContent>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              pl: 1,
                              pb: 1,
                            }}
                          >
                            <Typography component="div" variant="h5">
                              Total errors ({dataType})
                            </Typography>
                          </Box>
                        </Box>
                        <Box width="70%" height="100%">
                          <ChartMessagesStatus dataType={dataType} />
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} marginBottom={6}>
                    <Grid item lg={4} sm={6}>
                      <DoughnutProcessedRejected dataType={dataType} />
                    </Grid>
                    <PermissionsGate roles={[ROLES.admin]}>
                      <Grid item lg={4} sm={6}>
                        <DoughnutRejectedUknown dataType={dataType} />
                      </Grid>
                    </PermissionsGate>
                    <Grid item lg={4} sm={6}>
                      <DoughnutProcessedSat dataType={dataType} />
                    </Grid>
                    <PermissionsGate roles={[ROLES.admin]}>
                      <Grid item lg={4} sm={6}>
                        <DoughnutProcessedOperator dataType={dataType} />
                      </Grid>
                      <Grid item lg={4} sm={6}>
                        <DoughnutUnsubscribedOperators dataType={dataType} />
                      </Grid>
                    </PermissionsGate>
                    <Grid item lg={4} sm={6}>
                      <DoughnutRejectedSat dataType={dataType} />
                    </Grid>
                    <Grid item lg={4} sm={6}>
                      <DoughnutLogsStatus />
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ChartStatusPerDate />
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ChartObjectsPerDate />
                      </Grid>
                    </Grid>
                  </Grid>
                </TabPanel>
                <TabPanel value={currentTabIndex} index={1}>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12} md={6}>
                      <ChartPacketsProcessedRejectedPerSat
                        dataType={dataType}
                      />
                    </Grid>
                    <PermissionsGate roles={[ROLES.admin]}>
                      <Grid item xs={12} md={6}>
                        <ChartPacketsProcessedRejectedPerOperator
                          dataType={dataType}
                        />
                      </Grid>
                    </PermissionsGate>
                  </Grid>
                  <Divider sx={{ mt: '20px', mb: '40px' }} light />
                  <Grid container spacing={2} marginBottom={10}>
                    <Grid item xs={12}>
                      <ChartSnrPerSat />
                    </Grid>
                  </Grid>
                  <PermissionsGate roles={[ROLES.admin]}>
                    <Divider sx={{ mt: '20px', mb: '40px' }} light />
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item xs={12}>
                        <ChartSnrPerOperator />
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                  <Divider sx={{ mt: '20px', mb: '40px' }} light />
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12}>
                      <ChartRssiPerSat />
                    </Grid>
                  </Grid>
                  <PermissionsGate roles={[ROLES.admin]}>
                    <Divider sx={{ mt: '20px', mb: '40px' }} light />
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item xs={12}>
                        <Box style={{ height: '400px', margin: 'auto' }}>
                          <ChartRssiPerOperator />
                        </Box>
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                </TabPanel>
                <TabPanel value={currentTabIndex} index={2}>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12}>
                      <ChartLnsDelayPerDay />
                    </Grid>
                  </Grid>
                  <PermissionsGate roles={[ROLES.admin]}>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item xs={12}>
                        <Box style={{ margin: 'auto' }}>
                          <Typography
                            sx={{
                              textAlign: 'left',
                            }}
                            variant="h5"
                            gutterBottom
                          >
                            Satellite stats
                          </Typography>
                          <GridSatelliteData />
                        </Box>
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                  <Grid container spacing={2} marginBottom={2}>
                    <Grid item xs={12}>
                      <Box style={{ margin: 'auto' }}>
                        <Typography
                          sx={{
                            textAlign: 'left',
                          }}
                          variant="h5"
                          gutterBottom
                        >
                          Last batches
                        </Typography>
                        <GridLastBatches />
                      </Box>
                    </Grid>
                  </Grid>
                  <PermissionsGate roles={[ROLES.admin]}>
                    <Grid container spacing={2} marginBottom={2}>
                      <Grid item xs={12}>
                        <Box style={{ margin: 'auto' }}>
                          <Typography
                            sx={{
                              textAlign: 'left',
                            }}
                            variant="h5"
                            gutterBottom
                          >
                            Global DMP metrics
                          </Typography>
                          <GridGlobaleDashboardData />
                        </Box>
                      </Grid>
                    </Grid>
                  </PermissionsGate>
                  <Grid container spacing={2}>
                    <Grid item lg={6}>
                      <ChartMessagePerDay dataType={dataType} />
                    </Grid>
                    <Grid item lg={6}>
                      <ChartMessagesPerMonth dataType={dataType} />
                    </Grid>
                  </Grid>
                </TabPanel>
              </div>
            </Box>
          </>
        }
      />
    </React.Fragment>
  );
}
