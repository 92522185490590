import React from 'react';
import { Bar } from 'react-chartjs-2';
import Box from '@mui/material/Box';

import { useMetrics } from '../../Contexts/metrics-context';
import { barFormat } from '../../Utils/metricsSerializer';
import { addNonexistentAttribut, Capitalize } from '../../Utils/util';
import chartColors from './../../Constants/colors';
import defaultGraphOptions from './../../Constants/defaultGraphOptions';
import ChartWrapper from './ChartWrapper';

export default function ChartMessagesPerMonth({
  dataType,
}: {
  dataType: string;
}) {
  const { stateMetrics } = useMetrics();
  const barInputData = React.useMemo(
    () => barFormat(stateMetrics.metricsInput),
    [stateMetrics.metricsInput],
  );
  const barOutputData = React.useMemo(
    () => barFormat(stateMetrics.metricsOutput),
    [stateMetrics.metricsOutput],
  );
  const labelsInput = barInputData.map((value) => {
    return value.date;
  });
  const labelsOutput = barOutputData.map((value) => {
    return value.date;
  });
  const labelsChart = Array.from(new Set([...labelsInput, ...labelsOutput]));
  const inputSharedAttribut = addNonexistentAttribut(barInputData, labelsChart);
  const outputSharedAttribut = addNonexistentAttribut(
    barOutputData,
    labelsChart,
  );

  const dataMonthsProp = {
    labels: labelsChart,
    datasets: [
      {
        label: 'Input ' + dataType,
        data: labelsInput.map((value, index) =>
          dataType === 'messages'
            ? inputSharedAttribut[index].messages_count
            : inputSharedAttribut[index].packetLength,
        ),
        backgroundColor: chartColors.barCharts.backgrounds[0],
      },
      {
        label: 'Output ' + dataType,
        data: labelsInput.map((value, index) =>
          dataType === 'messages'
            ? outputSharedAttribut[index].messages_count
            : outputSharedAttribut[index].packetLength,
        ),
        backgroundColor: chartColors.barCharts.backgrounds[1],
      },
    ],
  };

  return (
    <React.Fragment>
      <ChartWrapper title={Capitalize(dataType) +" per month"}>
        <Box style={{ height: '400px', margin: 'auto' }}>
          <Bar options={defaultGraphOptions.bar} data={dataMonthsProp} />
        </Box>
      </ChartWrapper>
    </React.Fragment>
  );
}
