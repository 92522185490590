import apiClient from '../Utils/apiClient';
import jwt_decode from 'jwt-decode';
import { User } from '../Types';

const signIn = async (userInput: { email?: string; password?: string }) => {
  return apiClient.post(`login`, {
    email: userInput.email,
    password: userInput.password,
  });
};

const addTokenToHeader = async () => {
  const access_token = localStorage.getItem('Authorization')
    ? JSON.parse(localStorage.getItem('Authorization') || '')
    : null;

  const decoded = jwt_decode(access_token) as User;
  const dateNow = Math.floor(new Date().getTime() / 1000);

  if (decoded?.exp && decoded?.exp < dateNow) {
    localStorage.removeItem('user');
    localStorage.removeItem('Authorization');
    location.reload();
  }

  apiClient.defaults.headers.common['Authorization'] = '';
  delete apiClient.defaults.headers.common['Authorization'];
  if (access_token) {
    apiClient.defaults.headers.common[
      'Authorization'
    ] = `Bearer ${access_token}`;
  }
};

export default {
  signIn,
  addTokenToHeader,
};
