import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import type { ChartData } from 'chart.js';

import ChartWrapper from './ChartWrapper';
import { Typography } from '@mui/material';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function DoughnutWrapper({title, data, options}: {title: string, data: ChartData<'doughnut'>, options: object}) {
  return (
    <React.Fragment>
      <ChartWrapper title={title}>
        {
          data.datasets[0].data.length > 0
          ? (<Doughnut data={data} options={options} />)
          : (<Typography align="center">
              NO DATA FOUND
            </Typography>
          )
        }
      </ChartWrapper>
    </React.Fragment>
  )
}

DoughnutWrapper.defaultProps = {
  options: {}
}