import apiClient from '../Utils/apiClient';
import userApi from './user';

const listLoraOperatorsConfig = async () => {
  return apiClient.get(`lora-operators`);
};

const createLoraOperatorConfig = async (loraConfigData: FormData) => {
  userApi.addTokenToHeader();
  return apiClient.post(`lora-operators`, loraConfigData);
};

const updateLoraOperatorConfig = async (
  configId: string | undefined,
  loraConfigData: FormData,
) => {
  userApi.addTokenToHeader();
  return apiClient.post(`lora-operators/update`, loraConfigData);
};

const deleteLoraOperatorConfig = async (configId: string) => {
  userApi.addTokenToHeader();
  return apiClient.delete(`lora-operators/${configId}`);
};

const testConnectionLoraOperatorConfig = async (loraConfigData: FormData) => {
  userApi.addTokenToHeader();
  return apiClient.post(`lora-operators/test-connection`, loraConfigData);
};

export default {
  listLoraOperatorsConfig,
  createLoraOperatorConfig,
  updateLoraOperatorConfig,
  deleteLoraOperatorConfig,
  testConnectionLoraOperatorConfig,
};
