import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { Outlet } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import { makeStyles } from '@mui/styles';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

import {
  useFiltersList,
  resetFliterList,
} from '../Contexts/filtersList-context';
import { useMetrics, resetMetrics } from '../Contexts/metrics-context';
import { IconButton } from '@mui/material';
import { useLocalStorage } from '../Utils/custom-hooks';
import { User } from '../Types';
import PermissionsGate from '../Utils/permission/PermissionsGate';
import { ROLES } from '../Utils/permission/permission-maps';

const useStyles = makeStyles(() => ({
  main: {
    display: 'flex',
    padding: '0px',
  },
  btnMenu: {
    '&:hover': {
      background: '#a4b0be33',
    },
    backgroundColor: '#8e99b67a',
    borderRadius: '11px',
    marginLeft: '5em',
    border: 'none',
    width: '32px',
    height: '32px',
    cursor: 'pointer',
  },
  iconMenu: {
    display: 'flex !important',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100% !important',
  },
  linkItemIcon: {
    marginRight: '0.5rem',
  },
  linkItem: {
    textDecoration: 'none',
    color: '#FFF',
    marginLeft: '1em',
    fontSize: '1rem',
    display: 'flex',
    alignItems: 'center',
  },
}));

const Main = styled('main')(() => ({
  paddingTop: '64px',
}));

export default function LayoutPartner() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [user, setUser] = useLocalStorage<User | null>('user', null);
  const [, setAccessToken] = useLocalStorage<string | null>(
    'Authorization',
    null,
  );

  const { dispatchFiltersList } = useFiltersList();
  const { dispatchMetrics } = useMetrics();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleLogOut = () => {
    setUser(null);
    setAccessToken(null);
    resetMetrics(dispatchMetrics);
    resetFliterList(dispatchFiltersList);
    navigate(`/sign-in`);
    location.reload();
  };

  return (
    <Box>
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Link
            to="/"
            style={{
              textDecoration: 'none',
              color: 'inherit',
              display: 'flex',
              alignItems: 'center',
              marginRight: '1em',
            }}
          >
            <SatelliteAltIcon style={{ marginRight: '20' }} />
            <Typography variant="h5" noWrap component="div">
              ELO Partner Portal
            </Typography>
          </Link>

          <Link to="/dashboard" className={classes.linkItem}>
            <DashboardIcon className={classes.linkItemIcon} />
            Dashboard
          </Link>
          <PermissionsGate roles={[ROLES.admin]}>
            <Link to="/lora-configurations" className={classes.linkItem}>
              {' '}
              <SettingsIcon className={classes.linkItemIcon} /> Configuration
            </Link>
          </PermissionsGate>
          <Link to="/logs" className={classes.linkItem}>
            {' '}
            <ListAltIcon className={classes.linkItemIcon} /> Logs
          </Link>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Stack>
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{ marginRight: 6 }}
              >
                {user?.firstname + ' ' + user?.lastname}
              </Typography>
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{ marginRight: 10 }}
              >
                {user?.email}
              </Typography>
            </Stack>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}>
              <AccountCircleIcon sx={{ color: '#fff' }} />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Main>
        <Outlet />
      </Main>
    </Box>
  );
}
