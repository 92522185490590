import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import { doughnutFormat } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import { Capitalize } from './../../Utils/util';
import DoughnutWrapper from './DoughnutWrapper';

const errorLabels: { [key: string]: string } = {
  DELIVERY_FAILED: 'Delivery failed',
  UNCONTRACTED: 'Uncontracted',
  UNKOWN_OPERATOR: 'Unknown operator',
  UNPROVISIONED_OBJECT: 'Unprovisioned object',
  PACKETS_FOR_OPERATOR_EXCEEDED: 'Packets exceed',
  PACKETS_FOR_OPERATOR_REGION_EXCEEDED: 'Packets Region exceed',
  NOT_LORA_ONE: 'Not LoRaWAN',
  UNSUBSCRIBED: 'Unsubscribed',
};

export default function ChartMessagesStatus({
  dataType,
}: {
  dataType: string;
}) {
  const { stateMetrics } = useMetrics();
  const { data, errors } = doughnutFormat(stateMetrics.metricsErrors, dataType);
  const labels = errors.map((error) => errorLabels[error]);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };
  return (
    <React.Fragment>
      <DoughnutWrapper
        title={Capitalize(dataType) + " errors"}
        data={chartData}
        options={{
          aspectRatio: 4,
          plugins: {
            legend: {
              position: 'left' as const,
            }
          },
        }}
      />
    </React.Fragment>
  );
}
