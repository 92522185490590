import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import moment from 'moment';

import { useMetrics } from '../../Contexts/metrics-context';
import satellitesData, { SatsData } from '../../Utils/satellitesData';
// import satellitesMessages from '../../Utils/satellitesMessages';

const columns: GridColDef[] = [
  {
    field: 'satName',
    headerName: 'Satellite',
    width: 150,
  },
  {
    field: 'last_batch_time_received',
    headerName: 'last_batch/time_received',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'last_batch_nb_packets',
    headerName: 'last_batch/nb_packets',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'last_batch_nb_bytes',
    headerName: 'last_batch/nb_bytes',
    minWidth: 150,
    width: 200,
  },
  {
    field: 'last_batch_status',
    headerName: 'last_batch/status',
    width: 150,
  },
  {
    field: 'total_nb_batches',
    headerName: 'total/nb_batches',
    width: 150,
  },

  {
    field: 'total_nb_packets',
    headerName: 'total/nb_packets',
    width: 150,
  },

  {
    field: 'total_nb_bytes',
    headerName: 'total/nb_bytes',
    width: 150,
  },
];

function prepareRowsData(satsData: SatsData) {
  const rows: {
    id: number;
    satName: string;
    last_batch_time_received?: string;
    last_batch_nb_packets: number;
    last_batch_nb_bytes: number;
    last_batch_status: string;
    total_nb_batches: number;
    total_nb_packets: number;
    total_nb_bytes: number;
  }[] = [];
  //TODO use for loop instead of map
  Object.keys(satsData).forEach((sat, index) => {
    rows.push({
      id: index,
      satName: sat,
      last_batch_time_received: moment(satsData[sat].last_batch.time_received).format('DD-MM-YYYY hh:mm:ss'),
      last_batch_nb_packets: satsData[sat].last_batch.nb_packets,
      last_batch_nb_bytes: satsData[sat].last_batch.nb_bytes,
      last_batch_status: satsData[sat].last_batch.status,
      total_nb_batches: satsData[sat].total.nb_batches,
      total_nb_packets: satsData[sat].total.nb_packets,
      total_nb_bytes: satsData[sat].total.nb_bytes,
    });
  });
  return rows;
}

export default function GridSatelliteData() {
  const { stateMetrics } = useMetrics();

  const rows = React.useMemo(
    () => {
      const satsData = satellitesData(stateMetrics.metricsInput, stateMetrics.metricsOutput);
      return prepareRowsData(satsData);
    },
    [stateMetrics.metricsInput, stateMetrics.metricsOutput],
  );

  return (
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
      />
    </div>
  );
}
