import React from 'react';
import { ChartData, ScatterDataPoint } from 'chart.js';
import { Line } from 'react-chartjs-2';
import Box from '@mui/material/Box';

import ChartWrapper from './ChartWrapper';
import { useMetrics } from '../../Contexts/metrics-context';
import { useLogs } from '../../Contexts/logs-context';
import { statusLogs } from '../../Utils/logs';
import chartColors from '../../Constants/colors';
import defaultGraphOptions from '../../Constants/defaultGraphOptions';

export default function ChartStatusPerDate() {
  const { stateMetrics } = useMetrics();
  const { stateLogs } = useLogs();

  const statusPerDate = React.useMemo(
    () => statusLogs(stateLogs.data, stateMetrics.chartsFormat),
    [stateLogs.data, stateMetrics.chartsFormat],
  );

  const labelsUniq = React.useMemo(() => {
    const labels: string[] = [];
    Object.keys(statusPerDate).map((value) =>
      Object.keys(statusPerDate[value]).map((value2) => {
        labels.push(value2);
      }),
    );

    const uniqLabels = [...new Set(labels)];
    uniqLabels.sort(function (a, b) {
      return ('' + a).localeCompare(b);
    });
    return uniqLabels;
  }, [stateLogs.data, stateMetrics.chartsFormat]);

  /*eslint-disable */
  const data_: ChartData<
    'line',
    (number | ScatterDataPoint | null)[],
    unknown
  > = {
    labels: labelsUniq,
    datasets: [],
  };

  Object.keys(statusPerDate).map((value, index) => {
    data_.datasets.push({
      type: 'line' as const,
      label: value,
      data: statusPerDate[value] as any,
      borderColor: chartColors.lineCharts.borders[index],
      backgroundColor: chartColors.lineCharts.backgrounds[index],
    });
  });
  /*eslint-enable */

  return (
    <React.Fragment>
      <ChartWrapper title="Status per date">
        <div>
          <Box style={{ height: '400px', margin: 'auto' }}>
            <Line options={defaultGraphOptions.line} data={data_} />
          </Box>
        </div>
      </ChartWrapper>
    </React.Fragment>
  );
}
