import * as React from 'react';
import { useLocation } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import {
  DateRangePicker,
  DateRange,
} from '@mui/x-date-pickers-pro/DateRangePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import moment from 'moment';

import { useFilters } from '../../Contexts/filters-context';
import { useMetrics } from '../../Contexts/metrics-context';

export default function BasicDateRangePicker() {
  const { stateFilters, dispatchFilters } = useFilters();
  const { stateMetrics, dispatchMetrics } = useMetrics();
  const defaultFrom = new Date(stateFilters.filters.dateRange![0]); // eslint-disable-line
  const defaultTo = new Date(stateFilters.filters.dateRange![1]); // eslint-disable-line
  const [currentDateRange, setCurrentDateRange] = React.useState<
    DateRange<Date>
  >([defaultFrom, defaultTo]);
  // Using local period to not trigger stateFilters watcher before completing dateRange input
  const [currentPeriod, setCurrentPeriod] = React.useState<string | undefined>(
    stateFilters.filters.period,
  );
  const location = useLocation();

  const handlePeriodChange = React.useCallback(
    (event) => {
      let dateRange: DateRange<Date>;
      switch (event.target.value) {
        case 'currentDay':
          dateRange = [
            moment().startOf('day').toDate(),
            moment().endOf('day').toDate(),
          ];
          break;
        case 'currentMonth':
          dateRange = [moment().startOf('month').toDate(), moment().toDate()];
          break;
        case 'currentYear':
          dateRange = [moment().startOf('year').toDate(), moment().toDate()];
          break;
        case 'lastMonth':
          dateRange = [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ];
          break;
        case 'all':
          dateRange = [
            moment('01-01-2000', 'DD-MM-YYYY').toDate(),
            moment().toDate(),
          ];
          break;
        default:
          dateRange = [defaultFrom, defaultTo];
      }
      if (event.target.value === 'currentDay') {
        dispatchMetrics({
          type: 'set_chartsFormatMode',
          chartsFormatMode: 'today',
        });
      } else {
        dispatchMetrics({
          type: 'set_chartsFormatMode',
          chartsFormatMode: 'daily',
        });
      }
      setCurrentDateRange(dateRange);
      setCurrentPeriod(event.target.value);
      dispatchFilters({
        type: 'set_filters',
        filters: {
          period: event.target.value,
          dateRange: [
            moment(dateRange[0]).format('YYYY-MM-DD'),
            moment(dateRange[1]).format('YYYY-MM-DD'),
          ] as string[],
        },
      });
    },
    [currentPeriod],
  );

  const handleChartsFormat = React.useCallback((event) => {
    dispatchMetrics({
      type: 'set_chartsFormat',
      chartsFormat: event.target.value,
    });
  }, []);

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <FormControl fullWidth sx={{ mb: '20px' }}>
          <InputLabel id="demo-simple-select-label">Period</InputLabel>
          <Select
            value={currentPeriod}
            size="small"
            label="Period"
            onChange={handlePeriodChange}
          >
            <MenuItem value="currentDay">Today</MenuItem>
            <MenuItem value="currentMonth">Current month</MenuItem>
            <MenuItem value="lastMonth">Last month</MenuItem>
            <MenuItem value="currentYear">Current year</MenuItem>
            <MenuItem value="all">Always</MenuItem>
            <MenuItem value="custom">Custom</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            display: currentPeriod === 'custom' ? 'block' : 'none',
          }}
        >
          <DateRangePicker
            startText="From"
            endText="To"
            maxDate={new Date()} // max date is today
            value={currentDateRange}
            inputFormat={'dd/MM/yyyy'}
            onChange={(newValue) => {
              setCurrentDateRange(newValue);
            }}
            onClose={() =>
              dispatchFilters({
                type: 'set_filters',
                filters: {
                  dateRange: [
                    moment(currentDateRange[0]).format('YYYY-MM-DD'),
                    moment(currentDateRange[1]).format('YYYY-MM-DD'),
                  ] as string[],
                },
              })
            }
            renderInput={(startProps, endProps) => (
              <React.Fragment>
                <Stack spacing={2}>
                  <Stack direction="row" spacing={2}>
                    <TextField {...startProps} size="small" />
                    <TextField {...endProps} size="small" />
                  </Stack>
                  {location.pathname === '/dashboard' && (
                    <ToggleButtonGroup
                      color="primary"
                      value={stateMetrics.chartsFormat}
                      exclusive
                      onChange={handleChartsFormat}
                      aria-label="text alignment"
                    >
                      <ToggleButton value="DD/MM HH:00" sx={{ width: '100%' }}>
                        Hourly
                      </ToggleButton>
                      <ToggleButton value="DD/MM/YYYY" sx={{ width: '100%' }}>
                        Daily
                      </ToggleButton>
                    </ToggleButtonGroup>
                  )}
                </Stack>
              </React.Fragment>
            )}
          />
        </Box>
      </LocalizationProvider>
    </Box>
  );
}
