import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function ChartWrapper({
  title,
  children,
}: {
  title: string;
  children: JSX.Element;
}) {
  return (
    <Box>
      <Typography variant="h6" sx={{ m: 1, textAlign: 'center' }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
}
