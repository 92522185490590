import React from 'react';
import { useLocalStorage } from '../custom-hooks';
import { User } from '../../Types';

export default function PermissionsGate({
  children,
  roles = [],
}: {
  children: React.ReactNode;
  roles: string[];
}) {
  const [user] = useLocalStorage<User | null>('user', null);
  const role_user = user?.role || '';

  const hasRole = roles.some((role: string) => role === role_user);

  if (!hasRole) return <></>;

  return <>{children}</>;
}
