import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { useFilters } from '../../Contexts/filters-context';
import { useFiltersList } from '../../Contexts/filtersList-context';

export default function DeliveryStatusInput() {
  const { dispatchFilters } = useFilters();
  const { stateFiltersList } = useFiltersList();
  const [statuses, setStatuses] = React.useState<
    { label: string; value: string }[]
  >([]);
  const [defaultValue] = React.useState({
    label: 'Any status',
    value: '',
  });

  React.useEffect(() => {
    const formatedStatusList = stateFiltersList.statusList?.map((status) => ({
      label: status,
      value: status,
    }));
    formatedStatusList?.push({ label: 'No Response', value: 'No Response' });
    if (formatedStatusList)
      setStatuses([defaultValue].concat(formatedStatusList));
  }, [stateFiltersList.statusList]);

  return (
    <Autocomplete
      onChange={async (event, option) => {
        dispatchFilters({
          type: 'set_filters',
          filters: { resultCode: option.value },
        });
      }}
      isOptionEqualToValue={(selectedOption, option) =>
        selectedOption.value === option.value
      }
      disablePortal
      id="combo-box-devAddress"
      size="small"
      options={statuses}
      defaultValue={defaultValue}
      disableClearable={true}
      renderInput={(params) => (
        <TextField {...params} label="Status" placeholder="Select Status" />
      )}
    />
  );
}
