import { Routes, Route, Link, Navigate } from 'react-router-dom';

import LayoutPartner from '../Layouts/LayoutPartner';
import HomePartner from './../Screens/HomePartner';
import Configuration from '../Screens/Configuration/ListConfigurationScreen';
import EditLoraOperatorScreen from '../Screens/Configuration/EditLoraOperatorScreen';
import SignInPage from '../Screens/SignInPage';
import Logs from '../Screens/Logs';

import { useLocalStorage } from '../Utils/custom-hooks';
import { User } from '../Types';

function Stats() {
  return (
    <div>
      <h2>Stats</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  const [user] = useLocalStorage<User | null>('user', null);

  if (!user) {
    return <Navigate to="/sign-in" />;
  }

  return children;
}

export default function PartnerRoutes() {
  return (
    <Routes>
      <Route path="sign-in" element={<SignInPage />} />
      <Route
        path="/"
        element={
          <RequireAuth>
            <LayoutPartner />
          </RequireAuth>
        }
      >
        <Route index element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<HomePartner />} />
        <Route path="lora-configurations" element={<Configuration />} />
        <Route path="logs" element={<Logs />} />
        <Route path="stats" element={<Stats />} />
        <Route
          path="edit-lora-configuration/:loraOperatorName"
          element={<EditLoraOperatorScreen />}
        />
        <Route
          path="edit-lora-configuration"
          element={<EditLoraOperatorScreen />}
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
