const colors : { [key: string]: string } = {
  blue: '50, 136, 189',
  red: '213, 62, 79',
  yellow: '253, 174, 97',
  green: '75, 192, 192',
  orange: '244, 109, 67',
  purple: '94, 79, 169',
  grey: '201, 203, 207',
  burgundy: '158, 1, 66',
  umber: '149, 94, 66',
  pink: '224, 114, 164',
  darkGreen: '36, 48, 16',
  darkPurple: '43, 6, 30',
  cream: '244, 247, 190',
  olive: '111, 115, 47',
  peach: '255, 192, 159',
  black: '30, 27, 24',
  lime: '151, 219, 79',
  blackberry: '46, 31, 39'
};

const backgroundOpacity = 0.8;
const borderOpacity = 1;

function rgba(color: string, opacity: number) {
  return `rgba(${color}, ${opacity})`;
}

function getColorsPallette(
  opacity: number = backgroundOpacity,
  colorsCollection: { [key: string]: string } = colors,
) {
  return Object.keys(colorsCollection).reduce((acc: string[], key) => {
    acc.push(rgba(colorsCollection[key], opacity));
    return acc;
  }, []);

}

const chartColors = {
  lineCharts: {
    backgrounds: getColorsPallette(),
    borders:  getColorsPallette(borderOpacity),
  },
  barCharts: {
    backgrounds: getColorsPallette(),
    borders: getColorsPallette( borderOpacity),
  },
  doughnutCharts: {
    backgrounds: getColorsPallette(),
    borders:  getColorsPallette(borderOpacity),
  }
};

export default chartColors;

export { colors, rgba };
