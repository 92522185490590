import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAlt';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import Popover from '@mui/material/Popover';
import jwt_decode from 'jwt-decode';

import ForgotPasswordModal from '../Components/Partner/ForgotPasswordModal';
import { useLocalStorage } from '../Utils/custom-hooks';
import userApi from '../Api/user';
import { User } from '../Types';

/* eslint-disable @typescript-eslint/no-explicit-any */
function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Copyright © '}
      <Link color="inherit" href="#">
        EUTELSAT
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

interface State {
  password: string;
  showPassword: boolean;
}

const useStyles = makeStyles(() => ({
  title: {
    fontSize: '3rem',
    textTransform: 'uppercase',
    color: '#2BFAFA',
  },
  subtitle: {
    fontSize: '3rem',
    textTransform: 'uppercase',
    color: '#FFF',
    fontWeight: 'bolder',
  },
}));

function BasicPopover() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button aria-describedby={id} variant="contained" onClick={handleClick}>
        Show test accounts
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2">
            Admin: <b>admin@email.com/Test15@!</b>
          </Typography>
          <Typography variant="subtitle2">
            Wyld Networks@yopmail.com: <b>wyld-networks@yopmail.com/123456</b>
          </Typography>
          <Typography variant="subtitle2">
            Actility: <b>actility@yopmail.com/123456</b>
          </Typography>
          <Typography variant="subtitle2">
            SENET: <b>senet@yopmail.com/123456</b>
          </Typography>
          <Typography variant="subtitle2">
            Private-experimental: <b>private-experimental@yopmail.com/123456</b>
          </Typography>
          <Typography variant="subtitle2">
            Eutelsat-2: <b>eutelsat-2@yopmail.com/123456</b>
          </Typography>
        </Box>
      </Popover>
    </div>
  );
}

export default function SignInSide() {
  const navigate = useNavigate();
  const classes = useStyles();

  const [values, setValues] = React.useState<State>({
    password: '',
    showPassword: false,
  });
  const [loginError, setLoginError] = React.useState<string[]>([]);
  const [, setAccessToken] = useLocalStorage<string | null>(
    'Authorization',
    null,
  );
  const [, setUser] = useLocalStorage<User | null>('user', null);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const userInput = {
      email: data.get('email')?.toString(),
      password: data.get('password')?.toString(),
    };
    setLoginError([]);

    try {
      const { data } = await userApi.signIn(userInput);
      const userRetrieved = jwt_decode(data) as User;
      setUser(userRetrieved);
      setAccessToken(data);
      navigate(`/`);
    } catch (error) {
      setUser(null);
      setAccessToken(null);
      setLoginError((previous) => [
        ...previous,
        'Email or password is incorrect',
      ]);
      throw error;
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{
        height: '100vh',
        backgroundImage: `url(/images/sign-in-background.jpg)`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          pl: '50px',
          lineHeight: '1',
        }}
      >
        <div>
          <div className={classes.title}>Welcome to</div>
          <div className={classes.subtitle}>ELO PARTNER PORTAL</div>
        </div>
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box
          sx={{
            my: 8,
            mx: 4,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            height: 'calc(100% - 64px)',
          }}
        >
          <div>
            <img width="140" src="/images/eutelsat-logo.svg" alt="" />

            <Typography
              variant="h2"
              noWrap
              component="div"
              color="primary"
              sx={{
                mb: '20px',
                mt: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <SatelliteAltIcon
                color="primary"
                sx={{ fontSize: 40, mr: '10px' }}
              />
              ELO Partner Portal
            </Typography>
            <BasicPopover />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, px: '80px' }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="login"
                label="Login"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <FormControl sx={{ width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="password"
                  label="Password"
                  required
                  fullWidth
                  name="password"
                  autoComplete="current-password"
                  type={values.showPassword ? 'text' : 'password'}
                  value={values.password}
                  onChange={handleChange('password')}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              {loginError &&
                loginError.map((value) => (
                  <Typography variant="subtitle2" sx={{ color: 'error.main' }}>
                    {value}
                  </Typography>
                ))}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container>
                <Grid item xs>
                  <ForgotPasswordModal />
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </div>
        </Box>
      </Grid>
    </Grid>
  );
}
