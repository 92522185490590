import moment from 'moment';
import { Log } from '../Types';

export const statusLogs = (logs: Log[], chartsFormat = 'DD/MM/YYYY') => {
  const _logs = [...logs];
  _logs.sort(function (a, b) {
    return (
      new Date(a.recv_time || '').getTime() -
      new Date(b.recv_time || '').getTime()
    );
  });

  // Get Snr per Sat
  const status = _logs.reduce((previous: { [key: string]: Log[] }, current) => {
    const result_code = current?.result_code || 'No Response';

    if (!previous[result_code]) previous[result_code] = [];

    previous[result_code].push(current);
    return previous;
  }, {});

  const statusPerDate = {};
  Object.keys(status).map((value) => {
    return status[value].map((currentValue) => {
      const currentDate = currentValue?.recv_time
        ? moment(currentValue?.recv_time).format(chartsFormat)
        : 'null';
      if (!statusPerDate[value]) {
        statusPerDate[value] = {};
      }
      if (!statusPerDate[value][currentDate]) {
        statusPerDate[value][currentDate] = [];
      }
      statusPerDate[value][currentDate].push(currentValue);
      return statusPerDate;
    });
  });

  const countDates = {};
  Object.keys(statusPerDate).map((value) => {
    return Object.keys(statusPerDate[value]).map((value2) => {
      if (!countDates[value]) countDates[value] = {};

      if (!countDates[value][value2]) countDates[value][value2] = {};

      countDates[value][value2] = statusPerDate[value][value2].length;
    });
  });
  return countDates;
};

export const statusLogsDoughnut = (logs: Log[]): { [key: string]: number } => {
  const _logs = [...logs];
  _logs.sort(function (a, b) {
    return (
      new Date(a.recv_time || '').getTime() -
      new Date(b.recv_time || '').getTime()
    );
  });

  // Get Snr per Sat
  const status = _logs.reduce((previous: { [key: string]: Log[] }, current) => {
    const result_code = current?.result_code || 'No Response';

    if (!previous[result_code]) previous[result_code] = [];

    previous[result_code].push(current);
    return previous;
  }, {});

  const countStatus = {};
  Object.keys(status).map((value) => {
    if (!countStatus[value]) countStatus[value] = {};

    countStatus[value] = status[value].length;
  });
  return countStatus;
};
