import React from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Button, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { useNavigate } from 'react-router-dom';
import Chip from '@mui/material/Chip';
import DeleteIcon from '@mui/icons-material/Delete';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import DialogDeleteLoraConfig from './DialogDeleteLoraConfig';
import { useLoraConfigsList } from '../../../Contexts/lora-operators-config-context';
import { loraConfigType } from './../../../Types';

const parsingLoraConfig = (loraConfigs: loraConfigType[]) => {
  return loraConfigs.map((value) => {
    return {
      id: value._id,
      col1: value.name,
      col2: value.net_id,
      col3: value.method,
      col4: value.host,
    };
  });
};

const renderChip = (method: string) => {
  switch (method) {
    case 'BACKEND_INTERFACE':
      return <Chip label={method} color="primary" />;
    case 'PACKET_FORWARDER':
      return <Chip label={method} color="success" />;
    case 'SFTP':
      return <Chip label={method} color="success" />;
    default:
      return <Chip label={method} color="primary" />;
  }
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    title: {
      color: theme.palette.primary.main,
    },
  };
});

export default function GridOperators() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [rowIdSelected, setRowIdSelected] = React.useState('');

  const navigate = useNavigate();
  const { stateLoraConfigsList } = useLoraConfigsList();

  const rows: GridRowsProp = parsingLoraConfig(
    stateLoraConfigsList.lora_operators_configs,
  );

  const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Name', flex: 1 },
    { field: 'col2', headerName: 'NetID', width: 150, flex: 1 },
    {
      field: 'col3',
      headerName: 'Method',
      renderCell: (params) => {
        return renderChip(params.row.col3);
      },
      width: 150,
      flex: 1,
    },
    {
      field: 'col4',
      headerName: 'Host',
      width: 150,
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body2">
          {params.row.col3 === 'BACKEND_INTERFACE'
            ? params.row.col4
            : 'TODO: Display sftp server'}
        </Typography>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 100,
      sortable: false,
      renderCell: (params) => {
        return (
          <span>
            <IconButton
              onClick={() => {
                navigate(`/edit-lora-configuration/${params.row.col1}`);
              }}
              aria-label="fingerprint"
              color="secondary"
            >
              <ModeEditOutlineIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setOpen(true);
                setRowIdSelected(params.row.col2);
              }}
              aria-label="fingerprint"
              color="secondary"
            >
              <DeleteIcon />
            </IconButton>
          </span>
        );
      },
    },
  ];

  return (
    <div style={{ padding: 40 }}>
      <div className={classes.header}>
        <Typography gutterBottom variant="h1" className={classes.title}>
          Operators
        </Typography>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            navigate(`/edit-lora-configuration`);
          }}
        >
          ADD AN OPERATOR
        </Button>
      </div>
      <div style={{ height: 500, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} />
      </div>
      <DialogDeleteLoraConfig
        open={open}
        setOpen={setOpen}
        rowNameSelected={rowIdSelected}
      />
    </div>
  );
}
