import * as React from 'react';

import defaultInputs from './../Constants/defaultInputs';

type Filters = {
  // use camel case
  year?: string;
  period?: string;
  dateRange?: string[];
  month?: string;
  operator?: string;
  region?: string;
  devAddress?: string;
  satellite?: string;
  typePacket?: string;
  deliveryStatus?: string;
  packetLength?: string;
  resultCode?: string;
};
type Action =
  | {
      type: 'set_filters';
      filters: Filters;
    }
  | { type: 'reset_filters' }
  | { type: 'decrement' };
type Dispatch = (action: Action) => void;
export type StateFilters = { filters: Filters };
type filtersProviderProps = { children: React.ReactNode };

const defaultValue: Filters = {
  ...defaultInputs,
};

const filtersStateContext = React.createContext<
  { stateFilters: StateFilters; dispatchFilters: Dispatch } | undefined
>(undefined);

function filtersReducer(stateFilters: StateFilters, action: Action) {
  switch (action.type) {
    case 'set_filters': {
      return { filters: { ...stateFilters.filters, ...action.filters } };
    }
    case 'reset_filters': {
      return { filters: { ...defaultValue } };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function FiltersProvider({ children }: filtersProviderProps) {
  const [stateFilters, dispatchFilters] = React.useReducer(filtersReducer, {
    filters: defaultValue,
  });
  const value = { stateFilters, dispatchFilters };
  return (
    <filtersStateContext.Provider value={value}>
      {children}
    </filtersStateContext.Provider>
  );
}

function useFilters() {
  const context = React.useContext(filtersStateContext);
  if (context === undefined) {
    throw new Error('usefilters must be used within a FiltersProvider');
  }
  return context;
}

export { FiltersProvider, useFilters };
