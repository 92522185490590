import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import { countMessagesErrors } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import { Capitalize } from './../../Utils/util';
import DoughnutWrapper from './DoughnutWrapper';

export default function DoughnutProcessedRejected({
  dataType,
}: {
  dataType: string;
}) {
  const { stateMetrics } = useMetrics();

  const dataChart = {
    ALL: countMessagesErrors(stateMetrics.metricsOutput, dataType),
    REJECTED: countMessagesErrors(stateMetrics.metricsErrors, dataType),
  };

  const errorLabels: { [key: string]: string } = {
    ALL: 'Processed ' + Capitalize(dataType),
    REJECTED: 'Rejected ' + Capitalize(dataType),
  };

  const labels = Object.keys(dataChart).map((error) => errorLabels[error]);
  const data = Object.values(dataChart);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };
  return (
    <React.Fragment>
      <DoughnutWrapper
        title={'Processed-rejected ' + Capitalize(dataType)}
        data={chartData}
      />
    </React.Fragment>
  );
}
