import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import {
  TextField,
  Typography,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Chip,
  Tabs,
  Tab,
} from '@mui/material';

import {
  useLoraConfigsList,
  postLoraOperatorConfig,
  updateLoraOperatorConfig,
  testConnectionLoraOperatorConfig,
  getAllloraOperatorsConfigs,
} from '../../Contexts/lora-operators-config-context';
import { loraConfigType } from '../../Types';
import { validateEmail } from '../../Utils/util';
import TabPanel from '../../Components/TabPannel';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
  },
  inputConfig: { mr: 1, mb: 3 },
  downloadLink: {
    fontSize: 15,
  },
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: '20px !important',
    },
    subtitle: {
      color: theme.palette.primary.main,
      marginBottom: '20px !important',
      marginTop: '20px !important',
    },
  };
});

interface IFormInputs {
  id: string;
  name: string;
  net_id: string;
  devaddr_filtering: string;
  host: string;
  method: string;
  billing_report_list: string[];
  ca_certificate: string[];
  cert_certificate: string[];
  key_certificate: string[];
  sftp_host: string;
  sftp_ssh_key: string;
  sftp_username: string;
  sftp_password: string;
}

export default function EditLoraOperatorScreen() {
  const classes = useStyles();
  const navigate = useNavigate();
  const { stateLoraConfigsList, dispatchLoraConfigsList } =
    useLoraConfigsList();
  const { loraOperatorName } = useParams();
  const defaultValues = {
    name: '',
    net_id: '',
    devaddr_filtering: '',
    host: '',
    method: 'BACKEND_INTERFACE',
    ca_certificate: [],
    cert_certificate: [],
    key_certificate: [],
    billing_report_list: [],
    sftp_host: '',
    sftp_ssh_key: '',
    sftp_username: '',
    sftp_password: '',
  };

  const methods = ['BACKEND_INTERFACE', 'SFTP', 'PACKET_FORWARDER'];

  const [selectedLoraConfig, SetSelectedLoraConfig] = React.useState<
    loraConfigType | undefined
  >({ _id: '', ...defaultValues });

  const [open, setOpen] = React.useState(false);
  const [testConnection, setTestConnection] = React.useState(false);
  const [reportEmail, setReportEmail] = React.useState('');

  const handleClose = () => {
    setOpen(false);
  };

  const { register, handleSubmit, control, setValue, getValues, watch } =
    useForm<IFormInputs>({
      defaultValues: defaultValues,
    });

  React.useEffect(() => {
    async function getDefaultData() {
      await Promise.all([getAllloraOperatorsConfigs(dispatchLoraConfigsList)]);
    }
    if (loraOperatorName && loraOperatorName != undefined) {
      getDefaultData();
    }
  }, []);

  React.useEffect(() => {
    if (loraOperatorName && loraOperatorName != undefined) {
      const config = stateLoraConfigsList.lora_operators_configs.find(
        (value) => {
          return value.name == loraOperatorName;
        },
      );
      SetSelectedLoraConfig(config);
    }
  }, [stateLoraConfigsList]);

  React.useEffect(() => {
    setValue('name', selectedLoraConfig?.name || '');
    setValue('net_id', selectedLoraConfig?.net_id || '');
    setValue('host', selectedLoraConfig?.host || '');
    setValue('method', selectedLoraConfig?.method || methods[0]);
    setValue('devaddr_filtering', selectedLoraConfig?.devaddr_filtering || '');
    setValue('sftp_host', selectedLoraConfig?.sftp_host || '');
    setValue('sftp_ssh_key', selectedLoraConfig?.sftp_ssh_key || '');
    setValue('sftp_username', selectedLoraConfig?.sftp_username || '');
    setValue('sftp_password', selectedLoraConfig?.sftp_password || '');
    setValue('billing_report_list', selectedLoraConfig?.billing_report_list || []);
  }, [selectedLoraConfig]);

  const prepareFormData = (data: IFormInputs): FormData => {
    const formData = new FormData();

    formData.append('name', data.name);
    formData.append('net_id', data.net_id);
    formData.append('devaddr_filtering', data.devaddr_filtering);

    if (data.billing_report_list) {
      data.billing_report_list.forEach((value) => {
        formData.append('billing_report_list[]', value);
      });
    }

    formData.append('method', data.method);

    if (data.method == 'BACKEND_INTERFACE') {
      const ca_certificate = data?.ca_certificate && data?.ca_certificate[0];
      const cert_certificate =
        data?.cert_certificate && data?.cert_certificate[0];
      const key_certificate = data?.key_certificate && data?.key_certificate[0];
      formData.append('ca_certificate', ca_certificate);
      formData.append('cert_certificate', cert_certificate);
      formData.append('key_certificate', key_certificate);
      formData.append('host', data.host);
    }
    if (data.method == 'SFTP') {
      formData.append('sftp_host', data.sftp_host);
      formData.append('sftp_ssh_key', data.sftp_ssh_key);
      formData.append('sftp_username', data.sftp_username);
      formData.append('sftp_password', data.sftp_password);
    }

    if (selectedLoraConfig?._id) {
      formData.append('config_id', selectedLoraConfig._id);
    }
    return formData;
  };

  const onSubmit: SubmitHandler<IFormInputs> = async (data) => {
    const formData = prepareFormData(data);

    if (!selectedLoraConfig?.name) {
      await postLoraOperatorConfig(dispatchLoraConfigsList, formData);
    } else {
      await updateLoraOperatorConfig(
        dispatchLoraConfigsList,
        selectedLoraConfig?._id,
        formData,
      );
    }

    navigate(-1);
  };

  const onTestConnection = async () => {
    const data = getValues();

    const formData = new FormData();
    const ca_certificate = data?.ca_certificate && data?.ca_certificate[0];
    const cert_certificate =
      data?.cert_certificate && data?.cert_certificate[0];
    const key_certificate = data?.key_certificate && data?.key_certificate[0];

    formData.append('apiEndpoint', data.host);
    formData.append('operator', data.name);
    formData.append('ca', ca_certificate);
    formData.append('cert', cert_certificate);
    formData.append('key', key_certificate);

    try {
      const { data: result } = await testConnectionLoraOperatorConfig(
        dispatchLoraConfigsList,
        formData,
      );
      setOpen(true);

      const { status } = result;
      if (status !== 200) {
        setTestConnection(false);
        return;
      }
      setTestConnection(true);
    } catch (error) {
      setOpen(true);
      setTestConnection(false);
    }
  };

  const onReportEmailKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      if (validateEmail(reportEmail)) {
        setValue('billing_report_list', [
          ...getValues().billing_report_list,
          reportEmail,
        ]);
        setReportEmail('');
      }
    }
  };

  const handleReportEmailDelete = (email: string) => {
    setValue(
      'billing_report_list',
      getValues().billing_report_list.filter((value) => value !== email),
    );
  };

  const handleMethodChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setValue('method', methods[newValue]);
  };

  return (
    <Box sx={{ p: 4 }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        autoComplete="off"
        encType="multipart/form-data"
      >
        <div className={classes.header}>
          <Typography variant="h1" className={classes.title}>
            {!selectedLoraConfig?.name
              ? 'New operator settings'
              : 'Operator settings'}
          </Typography>
          <div>
            <Button
              variant="contained"
              color="warning"
              sx={{ marginRight: 2 }}
              onClick={() => {
                navigate(-1);
              }}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ marginRight: 2 }}
            >
              Save modifications
            </Button>
          </div>
        </div>
        <Grid container spacing={6}>
          <Grid item xs={6} sx={{ pr: 20 }}>
            <div>
              <Typography variant="h3" className={classes.subtitle}>
                General informations
              </Typography>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="standard"
                    disabled={selectedLoraConfig?.name !== ''}
                    required
                    size="small"
                    id="input-name"
                    label="Name"
                    fullWidth
                    sx={styles.inputConfig}
                  />
                )}
              />
              <Controller
                name="net_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="standard"
                    disabled={selectedLoraConfig?.name !== ''}
                    required
                    size="small"
                    id="input-net_id"
                    label="NetId"
                    fullWidth
                    sx={styles.inputConfig}
                  />
                )}
              />
              <Controller
                name="devaddr_filtering"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="standard"
                    size="small"
                    id="input-net_id"
                    label="DevAddrPool"
                    fullWidth
                    sx={styles.inputConfig}
                  />
                )}
              />
              {selectedLoraConfig?.billing_report_list && (
                <>
                  <Typography variant="h3" className={classes.subtitle}>
                    Billing reports list
                  </Typography>
                  <Box>
                    {watch('billing_report_list').map((value, index) => (
                      <Chip
                        key={index}
                        label={value}
                        onDelete={() => handleReportEmailDelete(value)}
                      />
                    ))}
                    <Box>
                      <TextField
                        variant="standard"
                        onChange={(e) => setReportEmail(e.target.value)}
                        value={reportEmail}
                        onKeyDown={onReportEmailKeyDown}
                        fullWidth
                        placeholder="Enter email"
                        margin="normal"
                      />
                    </Box>
                  </Box>
                </>
              )}
            </div>
          </Grid>
          <Grid item xs={6} sx={{ pr: 20 }}>
            <div>
              <Typography variant="h3" className={classes.subtitle}>
                Data delivery method
              </Typography>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs
                    value={methods.findIndex((m) => m === watch('method'))}
                    onChange={handleMethodChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="LoRaWAN - BI" value={0} />
                    <Tab label="sFTP" value={1} />
                  </Tabs>
                </Box>
                <TabPanel
                  value={methods.findIndex((m) => m === watch('method'))}
                  index={0}
                >
                  <Controller
                    name="host"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        size="small"
                        id="input-host"
                        required
                        label="Endpoint"
                        fullWidth
                        sx={styles.inputConfig}
                      />
                    )}
                  />
                  <Box marginBottom="1rem">
                    <Box display="flex">
                      <Typography sx={{ display: 'inline', mr: 2 }}>
                        CA Certificate (.pem)
                      </Typography>
                      <input
                        type="file"
                        // {...field}
                        style={{ marginLeft: 'auto' }}
                        {...register('ca_certificate')}
                      />
                    </Box>
                    <Box display="flex">
                      <Typography sx={{ display: 'inline', mr: 2 }}>
                        CRT Certificate (.pem)
                      </Typography>
                      <input
                        id="input-cert_certificate"
                        type="file"
                        style={{ marginLeft: 'auto' }}
                        {...register('cert_certificate')}
                      />
                    </Box>

                    <Box display="flex">
                      <Typography sx={{ display: 'inline', mr: 2 }}>
                        KEY Certificate (.pem)
                      </Typography>
                      <input
                        id="input-key_certificate"
                        type="file"
                        style={{ marginLeft: 'auto' }}
                        {...register('key_certificate')}
                      />
                    </Box>
                  </Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={onTestConnection}
                    style={{ display: 'none' }}
                  >
                    Test connection
                  </Button>
                </TabPanel>
                <TabPanel
                  value={methods.findIndex((m) => m === watch('method'))}
                  index={1}
                >
                  <Controller
                    name="sftp_host"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        required
                        size="small"
                        id="input-sftp_host"
                        label="Server"
                        fullWidth
                        sx={styles.inputConfig}
                      />
                    )}
                  />
                  <Controller
                    name="sftp_ssh_key"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        required
                        size="small"
                        id="input-sftp_ssh_key"
                        label="Private SSH Key (MASK)"
                        fullWidth
                        sx={styles.inputConfig}
                      />
                    )}
                  />
                  <Controller
                    name="sftp_username"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        required
                        size="small"
                        id="input-sftp_username"
                        label="Login"
                        fullWidth
                        sx={styles.inputConfig}
                      />
                    )}
                  />
                  <Controller
                    name="sftp_password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        variant="standard"
                        required
                        size="small"
                        id="input-sftp_password"
                        label="Password"
                        fullWidth
                        sx={styles.inputConfig}
                      />
                    )}
                  />
                </TabPanel>
              </Box>
            </div>
          </Grid>
        </Grid>
      </form>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Result of test connection
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {testConnection
              ? 'The connection tested with success'
              : 'The connection failed'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
