import React from 'react';

import { useMetrics } from '../../Contexts/metrics-context';
import { getOperatorsUnsubscribed } from '../../Utils/metricsSerializer';
import chartColors from './../../Constants/colors';
import DoughnutWrapper from './DoughnutWrapper';

export default function DoughnutUnsubscribedOperators({ dataType }: { dataType: string }) {
  const { stateMetrics } = useMetrics();
  const dataChart = getOperatorsUnsubscribed(
    stateMetrics.metricsInput,
    stateMetrics.metricsOutput,
    dataType
  );
  const labels = Object.keys(dataChart);
  const data = Object.values(dataChart);

  const chartData = {
    labels,
    datasets: [
      {
        label: '# of Errors',
        data,
        backgroundColor: chartColors.doughnutCharts.backgrounds,
        borderColor: chartColors.doughnutCharts.borders,
      },
    ],
  };

  return (
    <React.Fragment>
      <DoughnutWrapper
        title="Unsubscribed operators"
        data={chartData}
      />
    </React.Fragment>
  );
}
